import { createColumnHelper } from '@tanstack/react-table'
import { Fragment, useEffect, useState } from 'react'

import Badge from 'theme/ui/badge'
import DataTable, { IndeterminateCheckbox } from 'theme/ui/data-table'
import PopoverButtons from 'theme/ui/popover'
import Actions from './actions'

const OnboardedClientsTable = ({
	clients,
	meta,
	isLoading,
	pagination,
	setPagination,
	setSelectedRecords,
	rowSelection,
	setRowSelection,
}) => {
	const [actionActiveId, setActionActiveId] = useState(null)
	const [sorting, setSorting] = useState([])
	const onMouseEnter = (id) => setActionActiveId(id)
	const onMouseLeave = () => setActionActiveId(null)

	const columnHelper = createColumnHelper()
	useEffect(() => {
		const matchedIndexes = Object.keys(rowSelection).map(Number)
		const result = clients?.filter((client) => matchedIndexes?.includes(client.id))
		setSelectedRecords(result)
	}, [rowSelection])

	const columns = [
		{
			id: 'select',
			size: 20,
			header: ({ table }) => (
				<IndeterminateCheckbox
					{...{
						checked: table.getIsAllRowsSelected(),
						indeterminate: table.getIsSomeRowsSelected(),
						onChange: table.getToggleAllRowsSelectedHandler(),
					}}
				/>
			),
			cell: ({ row }) => (
				<div className='px-1'>
					<IndeterminateCheckbox
						{...{
							checked: row.getIsSelected(),
							disabled: !row.getCanSelect(),
							indeterminate: row.getIsSomeSelected(),
							onChange: row.getToggleSelectedHandler(),
						}}
					/>
				</div>
			),
		},
		columnHelper.accessor('index', {
			size: 20,
			header: () => <span>#</span>,
			cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		}),
		columnHelper.accessor((row) => row, {
			id: 'name',
			size: 150,
			header: () => <span>Name</span>,
			cell: (info) => {
				return (
					<span className='line-clamp-1 max-w-[180px] text-sm font-bold !text-gray-700'>
						{info.getValue()?.full_name}
					</span>
				)
			},
		}),
		columnHelper.accessor((row) => row, {
			id: 'tags',
			size: 200,
			header: () => <span>Tags</span>,
			cell: (info) => {
				const tagsData = info.getValue()?.tagStr?.split(', ')
				return (
					<span className='max-w-[180px]'>
						{tagsData && (
							<div className='flex flex-wrap items-center gap-2'>
								<Badge variant='primary'>{tagsData[0]}</Badge>
								{tagsData?.length > 1 ? (
									<PopoverButtons data={tagsData} title={'Tags'} />
								) : undefined}
							</div>
						)}
					</span>
				)
			},
		}),
		columnHelper.accessor('phone_number', {
			header: () => <span>Phone</span>,
			cell: (info) => {
				return <span>{info.getValue()}</span>
			},
		}),
		columnHelper.accessor('email', {
			header: () => <span>Email</span>,
			cell: (info) => <span>{info.getValue()}</span>,
		}),
		columnHelper.accessor((row) => row, {
			id: 'created_at',
			header: () => <span>Start date</span>,
			cell: (info) => (
				<Actions
					id={info.getValue()?.id}
					client={info.getValue()}
					isActionActive={actionActiveId === info?.row?.id}
				/>
			),
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={clients}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			setSorting={setSorting}
			sorting={sorting}
			meta={meta}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			setRowSelection={setRowSelection}
			rowSelection={rowSelection}
			isRowSelection={true}
		/>
	)
}

export default OnboardedClientsTable
