import { Fragment, useState } from 'react'
import { isAllowTo } from 'utilities/helpers'
import notification from 'utilities/notification'
import { permissions } from 'utilities/permissions'
import { useDeleteDocumentPackageMutation } from '../document-package-api'
import DeleteConfirmAlert from './delete-confirm-alert'

const Actions = ({ field, handleClickAddDocument, handleClickEditPackage }) => {
	const [isOpen, setIsOpen] = useState({
		open: false,
		id: undefined,
	})

	const onOpen = (id) => {
		setIsOpen({
			open: true,
			id,
		})
	}

	const onClose = () =>
		setIsOpen({
			open: false,
			id: undefined,
		})

	const [deleteDocumentPackage, { isLoading }] = useDeleteDocumentPackageMutation()

	const onDelete = async () => {
		const id = isOpen?.id
		const response = await deleteDocumentPackage(id)
		if (response?.data?.status === 200) {
			notification('success', response?.data?.message)
		}
		onClose()
	}

	return (
		<Fragment>
			<DeleteConfirmAlert
				isOpen={isOpen?.open}
				onClose={onClose}
				isLoading={isLoading}
				onConfirm={onDelete}
			/>
			<div className='flex items-center space-x-2'>
				{isAllowTo(permissions?.documentPackage?.edit) ? (
					<>
						<button
							type='button'
							className='text-primary flex size-8 items-center justify-center rounded border-none bg-gray-100 p-1.5 px-2 hover:bg-gray-200'
							title='Add Document'
							onClick={(e) => {
								e.stopPropagation()
								handleClickAddDocument(field?.id)
							}}>
							<i className='uil-plus' style={{ fontSize: 16 }} />
						</button>
						<button
							type='button'
							className='text-primary flex size-8 items-center justify-center rounded border-none bg-gray-100 p-1.5 px-2 hover:bg-gray-200'
							title='Edit Document Package'
							onClick={(e) => {
								e.stopPropagation()
								handleClickEditPackage(field?.id)
							}}>
							<i className='uil-edit-alt' style={{ fontSize: 16 }} />
						</button>
					</>
				) : undefined}

				{isAllowTo(permissions?.documentPackage?.delete) ? (
					<button
						type='button'
						className='text-danger flex size-8 items-center justify-center rounded border-none bg-gray-100 p-1.5 px-2 hover:bg-gray-200'
						title='Delete Document Package'
						onClick={(e) => {
							e.stopPropagation()
							onOpen(field?.id)
						}}>
						<i className='uil-trash' style={{ fontSize: 16 }} />
					</button>
				) : undefined}
			</div>
		</Fragment>
	)
}

export default Actions
