import { createApi } from '@reduxjs/toolkit/query/react'

import axiosBaseQuery from 'store/axios-base-query'
import { formatDate } from 'utilities/helpers'
import { CLIENT_API_ROUTES } from './routes'

export const invitedClientApi = createApi({
	reducerPath: 'InvitedClients',
	refetchOnFocus: true,
	keepUnusedDataFor: 0,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['InvitedClient'],
	endpoints: (builder) => ({
		getInvitedClients: builder.query({
			query: (params) => ({
				url: '/users/pending-invitation',
				method: 'GET',
				params: { ...params, roleName: 'client' },
			}),
			keepUnusedDataFor: 0,
			providesTags: ['InvitedClient'],
			transformResponse: (response, _, arg) => {
				const clients = Array.isArray(response?.data?.users)
					? response?.data?.users.map((client, idx) => {
							const user = client?.user
							return {
								id: user?.id,
								index: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
								full_name: `${user?.first_name} ${user?.last_name}`,
								email: user?.email,
								first_name: user?.first_name,
								last_name: user?.last_name,
								status: client?.status,
								group: client?.group,
								created_at: formatDate(user?.created_at),
								expired_at: formatDate(user?.invitation_expires_at),
							}
					  })
					: []

				return {
					clients,
					meta: response?.data?.meta,
				}
			},
		}),

		getOneClient: builder.query({
			query: (id) => ({
				url: `/users/${id}/user-onboard-detail`,
				method: 'GET',
			}),
			providesTags: ['InvitedClient'],
		}),

		getClientDetails: builder.query({
			query: (id) => ({
				url: CLIENT_API_ROUTES.findOne(id),
				method: 'GET',
			}),
			providesTags: ['InvitedClient'],
			transformResponse: (response) => ({
				client: response?.client,
			}),
		}),

		updateClientInvitation: builder.mutation({
			query: ({ payload, id }) => ({
				url: `/users/${id}/edit-user-on-board`,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['InvitedClient'],
		}),

		sendClientInvitation: builder.mutation({
			query: (payload) => ({
				url: '/users/invitation',
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['InvitedClient'],
		}),

		onboardClients: builder.mutation({
			query: (payload) => ({
				url: '/user-on-board/verify-clients',
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['InvitedClient'],
		}),
	}),
})

export const {
	useLazyGetInvitedClientsQuery,
	useLazyGetOneClientQuery,
	useLazyGetClientDetailsQuery,
	useSendClientInvitationMutation,
	useUpdateClientInvitationMutation,
	useOnboardClientsMutation,
} = invitedClientApi
