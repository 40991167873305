import React, { useEffect, useState } from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

const TagsDropdown = ({
	tagSearch,
	setTagSearch,
	tagList,
	selectedTags,
	handleTagChange,
	applyTags,
	assignWorkflow,
	selectedRecords,
	createTag,
	checkedTags,
	isAssignDisabled,
}) => {
	const [isTagOpen, setIsTagOpen] = useState(false)
	const [filteredTags, setFilteredTags] = useState(tagList)

	const toggleTagSection = () => {
		setIsTagOpen(!isTagOpen)
	}
	const handleTagSearch = (e) => {
		setTagSearch(e.target.value)
	}

	useEffect(() => {
		if (tagSearch) {
			setFilteredTags(tagList.filter((tag) => tag.includes(tagSearch)))
		} else {
			setFilteredTags(tagList)
		}
	}, [tagSearch])

	function IndeterminateCheckbox({ checked, indeterminate, tag, ...rest }) {
		const ref = React.useRef(null)

		useEffect(() => {
			if (ref.current) {
				ref.current.indeterminate = indeterminate
			}
		}, [indeterminate])

		return (
			<input
				type='checkbox'
				className='form-check-input m-0'
				ref={ref}
				checked={checked}
				{...rest}
				onChange={(e) => handleTagChange(e, tag)}
			/>
		)
	}

	useEffect(() => {
		if (tagList) {
			setFilteredTags(tagList)
		}
	}, [tagList])

	return (
		<>
			<Dropdown isOpen={isTagOpen} toggle={() => toggleTagSection()} className=''>
				<DropdownToggle className='m-0 !border !border-none  bg-transparent p-0'>
					<span type='button' className='btn btn-secondary !shadow-sm'>
						<span>Tags</span>{' '}
					</span>
				</DropdownToggle>

				<DropdownMenu className='dropdown-menu-md dropdown-menu-start  ms-4 p-0 shadow-md'>
					<div className='rounded '>
						<div className='mt-2 px-3'>
							<input
								className='form-control'
								type='text'
								value={tagSearch}
								onChange={handleTagSearch}
								placeholder='Search tags...'
							/>
						</div>
						<div className='custom-scroll mt-3 flex max-h-[154px] flex-col overflow-y-auto '>
							{filteredTags?.map((tag) => (
								<label
									key={tag}
									className='flex items-center gap-2 p-1 px-3  hover:bg-gray-200'>
									<IndeterminateCheckbox
										checked={selectedTags[tag]?.checked}
										indeterminate={selectedTags[tag]?.indeterminate}
										tag={tag}
									/>
									<span className='line-clamp-1 '>{tag}</span>
								</label>
							))}
						</div>
					</div>
					<div className='flex flex-col !border !border-t-gray-600 pb-3 pt-2'>
						<p
							className={`m-0 p-0 py-2 ps-3 hover:bg-gray-200 ${
								tagSearch
									? 'cursor-pointer  font-medium text-black'
									: ' font-normal text-gray-600  '
							} `}
							onClick={() => createTag(tagSearch)}>
							Create New Tags
						</p>
						{checkedTags?.length > 0 && isAssignDisabled && (
							<p
								className='m-0 cursor-pointer p-0 py-2 ps-3 font-medium text-black hover:bg-gray-200'
								onClick={assignWorkflow}>
								Assign Workflow
							</p>
						)}
						<p
							onClick={applyTags}
							className={`m-0 p-0 py-2 ps-3 hover:bg-gray-200 ${
								selectedRecords?.length > 0
									? 'cursor-pointer  font-medium text-black'
									: ' font-normal text-gray-600  '
							} `}>
							Apply Tags
						</p>
					</div>
				</DropdownMenu>
			</Dropdown>
		</>
	)
}

export default TagsDropdown
