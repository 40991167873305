import { Menu, Transition } from '@headlessui/react'
import emailIcon from 'assets/images/clients/email.png'
import locationIcon from 'assets/images/clients/location.png'
import userIcon from 'assets/images/clients/user.png'
import user4 from 'assets/images/users/avatar1.jpg'
import classNames from 'classnames'
import { ButtonWithLoader } from 'components/Common'
import {
	useUpdateClientProfileMutation,
	useUploadProfilePictureMutation,
} from 'modules/clients/client-apis'
import { useUpdateClientInvitationMutation } from 'modules/clients/invited-client-api'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Col } from 'reactstrap'
import AsyncImage from 'theme/ui/async-image'
import { FileUploadField } from 'theme/ui/forms'
import { showSuccessToast } from 'utils/commonFunctions'
import DeleteClientDetails from './delete-client-details'

const ClientProfile = ({ client, togStandard, isEditMode }) => {
	const params = useParams()
	const { id } = params
	const [saveLoader, setSaveLoader] = useState(false)
	const [isUploaded, setIsUploaded] = useState(false)
	const [fileId, setFileId] = useState(null)
	const [profileAvatar, setProfileAvatar] = useState(user4)
	const avatarRef = useRef(null)
	const [isUpload, setIsUpload] = useState(false)
	const profile_avatar = client?.avatar?.path

	const handleProfileUpload = () => {
		setIsUpload(!isUpload)
	}

	const [uploadProfilePicture, { isLoading: isUploading }] = useUploadProfilePictureMutation()
	const [updateClientProfile] = useUpdateClientProfileMutation()
	const [updateClient] = useUpdateClientInvitationMutation()

	const handleUpload = async (file) => {
		const formData = new FormData()
		formData.append('file', file)
		if (file) {
			const response = await uploadProfilePicture(formData)
			if (response?.data?.status === 200) {
				showSuccessToast(response?.data?.message)
				setFileId(response?.data?.data?.fileID)
				setIsUploaded(true)
			}
		}
	}
	const savePicture = async () => {
		setSaveLoader(true)
		const payload = {
			first_name: client?.first_name,
			last_name: client?.last_name,
			email: client?.email,
			phone_number: client?.phone_number,
			avatar: { id: fileId },
		}
		let response = null
		if (isEditMode) {
			response = await updateClient({ payload, id: client?.id })
		} else {
			response = await updateClientProfile({ payload, id })
		}
		setSaveLoader(false)
		if (response?.data?.status === 200 && response?.data?.data) {
			if (response?.data?.data?.avatar?.path) {
				setProfileAvatar(`${response?.data?.data?.avatar?.path}`)
			}
			showSuccessToast(response?.data?.message)
			if (avatarRef.current) {
				avatarRef.current.value = null
			}
		}
	}

	useEffect(() => {
		if (profile_avatar) {
			setProfileAvatar(`${client?.avatar?.path}`)
		}
	}, [profile_avatar])

	return (
		<Fragment>
			<div className='mb-2 flex items-start gap-4'>
				<div className='relative flex flex-shrink-0 flex-col items-center'>
					<div className='absolute bottom-14 right-[-5px] h-[20px] w-[20px] rounded-full border-[4px] border-white bg-[#34C38F]' />
					<AsyncImage
						src={profileAvatar}
						alt='profile'
						className='h-[175px] w-[175px] self-center rounded-xl !border-none object-cover'
					/>
					<button className='btn btn-link btn-sm' onClick={handleProfileUpload}>
						<i className='uil uil-edit' /> Change
					</button>
				</div>
				<div className='flex w-full flex-col gap-2'>
					<div className='flex items-center justify-between'>
						<h3 className='m-0 font-semibold'>
							{client?.first_name} {client?.last_name}
						</h3>
						{!isEditMode ? (
							<Menu as='div' className='relative inline-block text-left'>
								{({ open }) => (
									<>
										<Menu.Button
											className={classNames(
												'btn btn-secondary !border-none',
												{
													'!bg-main/20': open,
												},
											)}>
											<button>
												<i className='fas fa-ellipsis-v'></i>
											</button>
										</Menu.Button>

										<Transition
											as={Fragment}
											enter='transition ease-out duration-100'
											enterFrom='transform opacity-0 scale-95'
											enterTo='transform opacity-100 scale-100'
											leave='transition ease-in duration-75'
											leaveFrom='transform opacity-100 scale-100'
											leaveTo='transform opacity-0 scale-95'>
											<Menu.Items
												static
												className='absolute right-0 z-[1005] mt-2 w-56 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
												<Menu.Item>
													<DeleteClientDetails client={client} />
												</Menu.Item>
											</Menu.Items>
										</Transition>
									</>
								)}
							</Menu>
						) : undefined}
					</div>
					<div className='flex items-center gap-2'>
						<img src={userIcon} className='w-[20px]' />
						<span className='text-gray-500'>Client</span>
					</div>
					{(client?.userProfile?.city || client?.userProfile?.country) && (
						<div className='flex items-center gap-2'>
							<img src={locationIcon} className='w-[20px]' />
							<span className='text-gray-500'>{`${client?.userProfile?.city}, ${client?.userProfile?.country}`}</span>
						</div>
					)}
					<div className='flex items-center gap-2'>
						<img src={emailIcon} className='w-[20px]' />
						<span className='text-gray-500'>{client?.email}</span>
					</div>
					{!isEditMode ? (
						<div className='mt-2 flex gap-3'>
							{/* <button className='btn btn-primary px-4'>Message</button> */}
							<button
								className='btn !bg-[#50CD89] px-4 text-white'
								onClick={togStandard}>
								Add Note
							</button>
						</div>
					) : undefined}
				</div>
			</div>
			{isUpload && (
				<div className='row justify-content-center1 align-items-center mt-3'>
					<Col sm={7} md={7} xs={12} className='text-center'>
						<FileUploadField
							isUploading={isUploading}
							label='Upload a Picture'
							onUpload={handleUpload}
							allowedExtentions='.jpg,.jpeg,.jpe,.jif,.jfif,.jfi,.png,.gif,.webp,.tiff,.tif,.svg,.svgz'
							ref={avatarRef}
						/>
						{isUploaded && (
							<ButtonWithLoader
								className='btn btn-primary'
								type='button'
								onClick={savePicture}
								loading={saveLoader}>
								Save
							</ButtonWithLoader>
						)}
					</Col>
				</div>
			)}
		</Fragment>
	)
}

export default ClientProfile
