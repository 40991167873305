import * as yup from 'yup'
import DocumentTemplateForm from './document-template-form'
import { getInitialPdfFieldsMapping } from './helpers'

export const validationSchema = yup.object().shape({
	title: yup.string().required('Please enter the document title.'),
	min_clients: yup.mixed().required('Please select number of users.'),
	file: yup.mixed().required('Please select PDF file.'),
	document_mapping: yup.array().of(
		yup.object().shape({
			pdf_field: yup.string().required(),
			user: yup.mixed().required('Select user type'),
			field: yup.mixed().required('Select system field'),
		}),
	),
})

const createUserOption = (value) => ({
	label: value,
	value,
})

export const userListOptions = new Array(5).fill(0).map((_, idx) => createUserOption(idx + 1))

export const getInitialValues = (document) => {
	const initialValues = {
		title: document?.title || '',
		min_clients: document?.min_clients
			? createUserOption(document?.min_clients)
			: createUserOption(1),
		signature_required: document?.signature_required === true ? true : false,
		file: document?.file || null,
		file_path: document?.file_path || null,
		status: document?.status || 1,
		document_body: Array.isArray(document?.document_body) ? document?.document_body : [],
		documentBlob: null,
		document_mapping: getInitialPdfFieldsMapping(
			document?.document_mapping,
			document?.pdf_fields,
		),
		delete_document_mapping: Array.isArray(document?.delete_document_mapping)
			? document?.delete_document_mapping
			: [],
	}

	return initialValues
}

export const getCreateablePayload = (values) => {
	const payload = {
		title: values?.title || '',
		status: 0,
		file: values?.file,
		min_clients: values?.min_clients?.value,
		signature_required: values?.signature_required ? 1 : 0,
	}

	return payload
}

export const getPayload = (values) => {
	const payload = {}
	Object.assign(payload, values)

	Object.assign(payload, {
		min_clients: values?.min_clients?.value,
		document_mapping: JSON.stringify(values?.document_mapping),
		signature_required: values?.signature_required ? 1 : 0,
	})
	delete payload.file_path
	return payload
}

export const getPublishablePayload = (values, document_body, document) => {
	const payload = new FormData()

	payload.append('title', values?.title)
	payload.append('status', 1)
	payload.append('documentBlob', values?.documentBlob)
	payload.append('document_body', JSON.stringify(document_body))
	payload.append('document_mapping', JSON.stringify(values?.document_mapping))
	payload.append('delete_document_mapping', JSON.stringify(values?.delete_document_mapping))
	payload.append('file', JSON.stringify({ id: document?.file_id }))
	payload.append('min_clients', values?.min_clients?.value)
	payload.append('signature_required', values?.signature_required ? 1 : 0)

	return payload
}

export const getAutoSavePayload = (values) => {
	const payload = {
		min_clients: values?.min_clients?.value,
		document_mapping: JSON.stringify(values?.document_mapping),
		signature_required: values?.signature_required ? 1 : 0,
	}

	return payload
}

export * from './signature-document-context'

export default DocumentTemplateForm
