import { Link } from 'react-router-dom'

import { isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
import { NOTES_APP_ROUTES } from '../routes'

const NotesAction = ({ note }) => {
	const isUpdatePermission = isAllowTo(permissions?.note?.edit)
	return (
		<div className='mt-1 flex justify-between pt-1'>
			{isUpdatePermission ? (
				<Link to={NOTES_APP_ROUTES.edit(note?.id)}>
					<i className='uil uil-pen' />
				</Link>
			) : undefined}
			<div className='visibility'>
				{note.visibility === 1 ? (
					<>
						<i className='uil uil-globe' /> <span>Public</span>
					</>
				) : (
					<>
						<i className='uil uil-padlock' /> <span>Private</span>
					</>
				)}
			</div>
		</div>
	)
}

export default NotesAction
