/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { SpinLoader } from 'components/Common'
import { useClientSettingSaveMutation } from 'modules/clients/client-apis'
import { useEffect, useState } from 'react'
import { reduxForm } from 'redux-form'
import notification from 'utilities/notification'
import CustomFieldsForm from './CustomFields'
import useRelationViewQuery from './useRelationViewQuery'

const Info = ({ client, role, isEditMode }) => {
	const [fieldFormLoading, setFieldFormLoading] = useState(false)
	const [fields, setFields] = useState([])

	const { data, isLoading } = useRelationViewQuery(client)

	const [clientSettingSave] = useClientSettingSaveMutation()

	const saveFields = async (values) => {
		setFieldFormLoading(true)
		const payload = { ...values, user: { id: client?.id } }
		const response = await clientSettingSave(payload)
		setFieldFormLoading(false)
		if (response?.data?.status === 200 && Array.isArray(response?.data?.data)) {
			notification('success', response?.data?.message)
			const data = response?.data?.data?.[0]
			if (data?.length > 0) {
				const list = data.map((item) => ({
					id: item?.id,
					key: item?.user_key,
					value: item?.user_value,
				}))
				setFields(list)
			}
		}
		setFieldFormLoading(false)
	}

	useEffect(() => {
		if (data?.length > 0) {
			setFields(data)
		}
	}, [data])

	return (
		<div className='mb-4 rounded-lg bg-white'>
			{isLoading ? (
				<div className='bg-[#F2F5FF] p-4 py-3'>
					<h5 className='font-bold'>Custom Fields</h5>
					<p className='text-muted mb-0' style={{ fontSize: 13 }}>
						(To modify click on field)
					</p>
					<SpinLoader />
				</div>
			) : (
				<CustomFieldsForm
					loading={fieldFormLoading}
					initialValues={{ fields }}
					fields={fields}
					onSubmit={(values) => saveFields(values)}
				/>
			)}
		</div>
	)
}

const InfoForm = reduxForm({
	form: 'client_info',
	enableReinitialize: true,
})(Info)
export default InfoForm
