import notification from 'utilities/notification'
import request from 'utilities/request'
import * as yup from 'yup'
import { OWNER_INVITES_API_ROUTES } from '../routes'
import UserInviteForm from './user-invite-form'

export const validationSchema = yup.object().shape({
	role: yup.mixed().required('Please select role'),
	email: yup.string().email('Please enter valid email address').required('Please email adress'),
	expiredIn: yup.mixed().required('Please select expiration time'),
})

export const getPayload = (values) => {
	return {
		email: values?.email,
		group_id: values?.role?.value,
		expiredIn: values?.expiredIn?.value,
		isOwner: values?.isOwner,
	}
}

export const sendInvite = (values) => {
	const payload = getPayload(values)
	return request.post(OWNER_INVITES_API_ROUTES.create(), payload).then((response) => {
		if (response?.status === 200) {
			notification('success', response?.message)
		}
	})
}

export default UserInviteForm
