import { snakeCase } from 'lodash'
import { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import { getWorkflowUrl } from '.'
import { useLazyGetAssignedWorkflowsQuery } from '../../assigned-workflows/assigned-workflow-apis'
import AssignedWorkflowsTable from './assinged-workflows-table'
import Filters from './filters'

const AssignedWorkflowsContainer = () => {
	const location = useLocation()
	const history = useHistory()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination

	useEffect(() => {
		if (pagination) {
			const parseQueryString = getParseQueryString(location?.search)
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			history.push(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	const { defaultGroup } = useSelector((state) => state.Permissions)

	const [fetchAssignedWorkflow, { data, isFetching: isLoading }] =
		useLazyGetAssignedWorkflowsQuery()

	useEffect(() => {
		const group = snakeCase(defaultGroup?.group?.name)
		const stringUrl = getWorkflowUrl(group)
		if (location?.search && stringUrl) {
			fetchAssignedWorkflow({
				params: getParseQueryString(location?.search),
				url: stringUrl,
			})
		}
	}, [location?.search])

	return (
		<Fragment>
			<Filters />
			<AssignedWorkflowsTable
				workflows={data?.workflows || []}
				meta={data?.meta}
				isLoading={isLoading}
				pagination={{
					pageSize: limit,
					pageIndex: page - 1,
				}}
				setPagination={setPagination}
			/>
		</Fragment>
	)
}

export default AssignedWorkflowsContainer
