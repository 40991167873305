import * as base64 from 'base-64'
import * as yup from 'yup'

import apiEndpoints from 'helpers/apiEndpoints'
import { getParseQueryString } from 'utilities/helpers'
import notification from 'utilities/notification'
import request from 'utilities/request'
import WorkflowAssignForm from './workflow-assign-form'
import { createWorkflowOption } from './workflow-dropdown'

const coApplicantSchema = yup.object().shape({
	user: yup
		.object()
		.shape({
			email: yup.string(),
			fullName: yup.string(),
			value: yup.number(),
		})
		.required('Please select co applicant'),
	screen_type: yup.string().required('Please choose workflow representation'),
})

export const assignValidationSchema = yup.object().shape({
	workflow: yup.mixed().required('Please select a Workflow Template'),
	advisor: yup.mixed().required('Please select an Advisor'),
	tags: yup.array().optional(),
	client: yup.object().when('tags', {
		is: (value) => {
			return Array.isArray(value) && value?.length === 0
		},
		then: (schema) =>
			schema
				.shape({
					user: yup.mixed().required('Please select a client'),
					screen_type: yup.string().required('Please choose workflow representation'),
				})
				.required(),
		otherwise: (schema) => schema.optional(),
	}),
	userCount: yup.number(),
	co_applicants: yup.array().of(coApplicantSchema).optional(),
})

export const getAssignInitialValues = (search) => {
	const queryString = getParseQueryString(search)
	const queryParams = new URLSearchParams(location.search)
	const checkedKeys = queryParams.getAll('tags')
		? queryParams.getAll('tags')[0]?.split(',')
		: null
	const decodedUser = queryString?.user ? base64.decode(queryString?.user) : '{}'
	const user = JSON.parse(decodedUser)

	const decodedWorkflow = queryString?.workflow ? base64.decode(queryString?.workflow) : '{}'
	const workflow = JSON.parse(decodedWorkflow)

	try {
		return {
			workflow: workflow?.title ? createWorkflowOption(workflow) : undefined,
			client: user?.id ? { label: user?.label, value: user?.id } : undefined,
			tags: Array.isArray(checkedKeys) && checkedKeys.length > 0 ? checkedKeys : [],
		}
	} catch (_) {
		return {
			workflow: undefined,
			user: undefined,
		}
	}
}

export const getWorkflowAssignPayload = (values) => {
	const userCount = values?.userCount

	const payload = {
		workflowTemplate: {
			id: values?.workflow?.value,
		},
		advisor: {
			id: values?.advisor?.value,
			signPriority: userCount + 1,
			screen_type: 'same_page',
		},
		reviewers: Array.isArray(values?.reviewers)
			? values?.reviewers.map((reviewer) => ({ id: reviewer?.value }))
			: [],
		receiver_emails: Array.isArray(values?.receiver_emails)
			? values?.receiver_emails.map((email) => email?.value)
			: [],
		tags: Array.isArray(values?.tags) ? values?.tags : [],
		co_applicants: [],
		notes: values?.notes || '',
	}

	if (values?.tags?.length === 0) {
		payload.client = {
			id: values?.client?.user?.value,
			signPriority: 1,
			screen_type: values?.client?.screen_type,
		}
	}

	if (values?.final_reviewer) {
		Object.assign(payload, {
			finalReviewer: {
				id: values?.final_reviewer?.value,
			},
		})
	}

	if (Array.isArray(values?.co_applicants) && values?.co_applicants.length && userCount > 1) {
		const applicants = values?.co_applicants.map((applicant, idx) => ({
			key: `applicant_${idx + 1}`,
			signPriority: idx + 2,
			value: applicant?.user?.value,
			screen_type: applicant?.screen_type,
		}))

		Object.assign(payload, { co_applicants: applicants })
	}
	return payload
}

export const validateWorkflow = (id) => {
	return request.get(`${apiEndpoints.workflowAssignEligible}/${id}`).then((response) => {
		if (response?.status === 200 && !response?.data) {
			Promise.reject(new Error(response?.message))
		} else {
			Promise.resolve()
		}
	})
}

export const assignWorkflow = (payload) => {
	return request.post(`${apiEndpoints.workflow}/assign`, payload).then((response) => {
		if (response?.status === 200) {
			notification('success', response?.message)
		} else {
			notification('warn', response?.message)
		}
	})
}

export { default as ClientWorkflowAssignForm } from './client-workflow-assign-form'
export default WorkflowAssignForm
