import { Tab } from '@headlessui/react'
import { FormikProvider, useFormik } from 'formik'
import { Fragment, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { SoftLoader } from 'components/Common'
import { useFormBuilder } from 'contexts/formbuilder-provider'
import { useTabs } from 'contexts/tabs-context'
import notification from 'utilities/notification'
import {
	getAutoSavePayload,
	getCreateablePayload,
	getInitialValues,
	getPublishablePayload,
	validationSchema,
} from '.'
import {
	useAutoSaveDocumentMutation,
	useCreateDocumentMutation,
	useUpdateDocumentMutation,
} from '../document-api'
import { DOCUMENT_TEMPLATE_APP_ROUTES } from '../routes'
import { getMappedFields } from './helpers'
import TabsContent from './tabs-content'
import TabsFooter from './tabs-footer'
import TabsHeader, { getTabs } from './tabs-header'

const DocumentTemplateForm = ({ document }) => {
	const params = useParams()
	const { activeTab, setActiveTab } = useTabs()
	const history = useHistory()
	const initialValues = useMemo(() => getInitialValues(document), [document])
	const [createDocument] = useCreateDocumentMutation()
	const [updateDocument] = useUpdateDocumentMutation()
	const [autoSaveDocument, { isLoading: isAutoSaving }] = useAutoSaveDocumentMutation()
	const formBuilder = useFormBuilder()

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				// const payload = getPayload(values)
				setSubmitting(true)
				const nextTab = activeTab + 1
				const totalTabs = getTabs(values?.signature_required).length

				if (nextTab <= totalTabs) {
					if (activeTab === 1) {
						if (document) {
							setActiveTab(nextTab)
						} else {
							// create document for the first time
							const createablePayload = getCreateablePayload(values)
							const response = await createDocument(createablePayload)
							if (response?.data?.status === 200) {
								const document = response?.data?.data
								history.push(DOCUMENT_TEMPLATE_APP_ROUTES.edit(document?.id))
							}
						}
					} else {
						setActiveTab(nextTab)
					}
				} else {
					// publish the document
					if (formBuilder) {
						const document_body = formBuilder?.actions?.getData() || []
						const mappedFields = getMappedFields(document_body, formik)
						formik.setFieldValue('document_body', mappedFields)
						const publishablePayload = getPublishablePayload(
							values,
							mappedFields,
							document,
						)

						// publishablePayload.forEach((e) => {
						// 	console.log(e.length > 1000 ? JSON.parse(e) : e)
						// })

						const response = await updateDocument({
							id: params?.id,
							payload: publishablePayload,
						})
						if (response?.data?.status === 200) {
							notification('success', response?.data?.message)
							history.push(DOCUMENT_TEMPLATE_APP_ROUTES.findAll())
						}
					} else {
						notification('warn', 'Please wait until the formuilder is initialized')
					}
				}
				setSubmitting(false)
			} catch (error) {
				setSubmitting(false)
				notification('error', error?.message)
			}
		},
	})

	const onAutoSave = async () => {
		if (params?.id) {
			const payload = getAutoSavePayload(formik.values)
			await autoSaveDocument({ payload, id: params?.id })
		}
	}

	return (
		<Fragment>
			{isAutoSaving ? <SoftLoader /> : undefined}
			<FormikProvider value={formik}>
				<form onSubmit={formik.handleSubmit} className='space-y-6'>
					<Tab.Group selectedIndex={activeTab - 1}>
						<TabsHeader formik={formik} />
						<TabsContent formik={formik} document={document} onAutoSave={onAutoSave} />
						<TabsFooter formik={formik} />
					</Tab.Group>
				</form>
			</FormikProvider>
		</Fragment>
	)
}

export default DocumentTemplateForm
