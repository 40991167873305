import classNames from 'classnames'
import { ButtonWithLoader } from 'components/Common'
import { useDeleteClientSettingMutation } from 'modules/clients/client-apis'
import { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FormFeedback, Input } from 'reactstrap'
import { Field, FieldArray, reduxForm } from 'redux-form'
import Swal from 'sweetalert2'
import { showSuccessToast } from 'utils/commonFunctions'
import { required } from 'utils/formValidation'

const CustomFields = (props) => {
	const { handleSubmit, loading, fields } = props

	return (
		<Fragment>
			<form>
				<FieldArray name='fields' component={ArrayField} initialValues={fields} />
				<div className='p-4 py-3 pt-0 text-center'>
					<ButtonWithLoader type='submit' onClick={handleSubmit} loading={loading}>
						Submit
					</ButtonWithLoader>
				</div>
			</form>
		</Fragment>
	)
}

const CustomFieldsForm = reduxForm({
	form: 'custom_fields',
	enableReinitialize: true,
})(CustomFields)

export default CustomFieldsForm

const validate = {
	key: required('Key'),
	value: required('Value'),
}

const ArrayField = ({ fields }) => {
	const { custom_fields } = useSelector((state) => state.form)
	const initialValues = custom_fields?.values?.fields || []
	// const [activeField, setActiveField] = useState(null)

	useEffect(() => {
		if (fields.length === 0) {
			fields.push({})
		}
	}, [fields])

	const [deleteClientSetting] = useDeleteClientSettingMutation()

	const handleDelete = async (e, id, idx) => {
		e.preventDefault()
		if (id) {
			Swal.fire({
				title: 'Are you sure you want to Delete ?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes',
			}).then(async (result) => {
				if (result.isConfirmed) {
					const button = e.target
					button.disabled = true
					const response = await deleteClientSetting(id)
					if (response?.data?.status === 200) {
						showSuccessToast(response?.data?.message)
						fields.remove(idx)
					}
				}
			})
		} else {
			fields.remove(idx)
		}
	}

	// const onFocus = (e, idx) => setActiveField(idx)
	// const onBlur = () => setActiveField(null)

	const addField = (e) => {
		e.preventDefault()
		fields.push({})
	}

	return (
		<Fragment>
			<div className='flex items-center justify-between bg-[#F2F5FF] p-4 py-3'>
				<div>
					<h5 className='font-bold'>Custom Fields</h5>
					<p className='text-muted mb-0' style={{ fontSize: 13 }}>
						(To modify click on field)
					</p>
				</div>
				<button className='btn btn-outline-primary' onClick={addField}>
					<i className='uil uil-plus' /> Add New
				</button>
			</div>
			<div className='p-[0.75rem]'>
				<table className='table-1 mb-0'>
					<tbody>
						{fields.map((field, idx) => {
							let item = initialValues[idx] || {}
							const id = item?.id
							// let isFocused = idx === activeField
							return (
								<tr className='hover:!bg-white' key={idx}>
									<td className='!border-none' style={{ verticalAlign: 'top' }}>
										<Field
											type='hidden'
											name={`${field}.id`}
											component='input'
										/>
										<Field
											name={`${field}.key`}
											component={TextField}
											placeholder='Field Key'
											validate={validate.key}
											className={{ fontBold: true }}
											// onFocus={(e) => onFocus(e, idx)}
											// onBlur={onBlur}
										/>
									</td>
									<td className='!border-none' style={{ verticalAlign: 'top' }}>
										<Field
											name={`${field}.value`}
											placeholder='Field Value'
											component={TextField}
											validate={validate.value}
											className={{ fontBold: true }}
											// onFocus={(e) => onFocus(e, idx)}
											// onBlur={onBlur}
										/>
									</td>
									<td className='!border-none' style={{ verticalAlign: 'top' }}>
										<button
											className='btn text-primary w-full !bg-[#F2F5FF]'
											onClick={(e) => handleDelete(e, id, idx)}>
											<i className='uil uil-trash' />
										</button>
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>
		</Fragment>
	)
}

const TextField = ({ meta, className, input, ...rest }) => {
	const [val] = useState('')
	const { touched, invalid, error } = meta

	return (
		<Fragment>
			<div className='form-group'>
				<Input
					{...rest}
					{...input}
					value={val || input.value}
					invalid={touched && Boolean(invalid)}
					className={classNames('form-control', { ...className })}
				/>
				{touched && error && (
					<FormFeedback className='text-danger'>{touched && error}</FormFeedback>
				)}
			</div>
		</Fragment>
	)
}
