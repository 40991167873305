import { Fragment, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { getPaginationParams, getParseQueryString, getTagsParams } from 'utilities/helpers'
import notification from 'utilities/notification'
import { toQueryString } from 'utils/commonFunctions'
import { useAssignTagsMutation, useLazyGetClientsQuery, useLazyGetTagsQuery } from '../client-apis'
import Filters from './filters'
import OnboardedClientsTable from './onboarded-clients-table'

const OnboardedClientsContainer = () => {
	const location = useLocation()
	const history = useHistory()
	const { page, limit } = getPaginationParams(location?.search)
	const { tags } = getTagsParams(location?.search)
	const [pagination, setPagination] = useState({
		pageIndex: page - 1,
		pageSize: limit,
		tags: tags,
	})
	const { pageIndex, pageSize } = pagination
	const [tagList, setTagList] = useState([])
	const [uniqueTagList, setUniqueTagList] = useState([])
	const [tagSearch, setTagSearch] = useState(null)
	const [selectedRecords, setSelectedRecords] = useState([])
	const [selectedTags, setSelectedTags] = useState({})
	const [rowSelection, setRowSelection] = useState({})
	const [fetchClients, { data, isFetching: isLoading }] = useLazyGetClientsQuery()
	const [checkedTags, setCheckedTags] = useState([])
	const [fetchAllTags, { data: tagsData }] = useLazyGetTagsQuery()
	const [assignTags] = useAssignTagsMutation()
	const [isAssignDisabled, setIsAssignDisabled] = useState(true)

	const checkTags = () => {
		return checkedTags.every((item) => uniqueTagList.includes(item))
	}

	useEffect(() => {
		setIsAssignDisabled(checkTags())
	}, [checkedTags, selectedRecords])

	useEffect(() => {
		if (pagination) {
			const parseQueryString = getParseQueryString(location?.search)
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			history.push(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	useEffect(() => {
		if (location?.search) {
			const parseQueryString = getParseQueryString(location?.search)
			fetchClients({
				...getParseQueryString(location?.search),
				role: 'client',
			})
			if (parseQueryString?.tags) {
				const listTags = parseQueryString?.tags?.split(',')
				const newSelectedTags = {}
				listTags.forEach((tag) => {
					newSelectedTags[tag] = {
						checked: true,
						indeterminate: false,
					}
				})
				setSelectedTags(newSelectedTags)
			} else {
				setCheckedTags([])
				setSelectedTags({})
			}
		}
	}, [location?.search])

	useEffect(() => {
		if (data) {
			fetchAllTags()
		}
	}, [data, fetchAllTags])

	useEffect(() => {
		if (tagsData) {
			setTagList(tagsData?.tagsData)
			setUniqueTagList(tagsData?.tagsData)
		}
	}, [tagsData])

	useEffect(() => {
		const getTagsList = Object.keys(selectedTags).filter((key) => selectedTags[key].checked)
		setCheckedTags(getTagsList)
	}, [selectedTags])

	useEffect(() => {
		const newSelectedTags = {}
		if (selectedRecords?.length > 0) {
			tagList.forEach((tag) => {
				const tagInAllRecords = selectedRecords?.every((record) =>
					record?.tags?.includes(tag),
				)
				const tagInSomeRecords = selectedRecords?.some((record) =>
					record?.tags?.includes(tag),
				)
				newSelectedTags[tag] = {
					checked: tagInAllRecords,
					indeterminate: tagInSomeRecords && !tagInAllRecords,
				}
			})
			setSelectedTags(newSelectedTags)
		} else {
			setSelectedTags({})
			setCheckedTags([])
			if (location?.search) {
				const parseQueryString = getParseQueryString(location?.search)
				if (parseQueryString?.tags) {
					const listTags = parseQueryString?.tags?.split(',')
					const newSelectedTags = {}
					listTags.forEach((tag) => {
						newSelectedTags[tag] = {
							checked: true,
							indeterminate: false,
						}
					})
					setSelectedTags(newSelectedTags)
				}
			}
		}
	}, [selectedRecords])

	const createTag = (tagName) => {
		if (tagName) {
			setTagList((prevTags) => [...(prevTags || []), tagName?.trim()])
			setTagSearch('')
		}
	}

	const handleTagChange = (e, tag) => {
		const tagChecked = selectedTags[tag]?.checked
		if (selectedRecords?.length > 0) {
			setSelectedRecords((prevSelectedRecords) =>
				prevSelectedRecords?.map((record) => {
					const tags = record?.tags || []
					const hasTag = tags.includes(tag)

					if (tagChecked) {
						return {
							...record,
							tags: tags.filter((t) => t !== tag),
						}
					} else {
						return {
							...record,
							tags: hasTag ? tags : [...tags, tag],
						}
					}
				}),
			)
		} else {
			const isChecked = e.target.checked
			const newSelectedTags = { ...selectedTags }
			newSelectedTags[tag] = {
				checked: isChecked,
				indeterminate: false,
			}
			setSelectedTags(newSelectedTags)
			const checkedTaglist = Object.keys(newSelectedTags).filter(
				(key) => newSelectedTags[key].checked,
			)
			const tagsString = checkedTaglist.join(',')
			const encodedTags = encodeURIComponent(tagsString)
			setPagination((preRec) => ({
				...preRec,
				tags: encodedTags,
			}))
			history.push(`${window.location.pathname}?tags=${encodedTags}`)
		}
	}
	const applyTags = async () => {
		if (selectedRecords?.length > 0) {
			const tagsPayload = []
			selectedRecords?.forEach((record) => {
				tagsPayload.push({ id: record?.id, tags: record?.tags })
			})
			const response = await assignTags(tagsPayload)
			if (response?.data?.status === 201) {
				notification('success', response?.data?.message)
			}
			setSelectedRecords([])
			setRowSelection({})
		}
	}

	const assignWorkflow = () => {
		const tagsString = checkedTags.join(',')
		const encodedTags = encodeURIComponent(tagsString)
		history.push(`/workflows/assign?tags=${encodedTags}`)
	}

	return (
		<Fragment>
			<Filters
				tagList={tagList}
				setTagSearch={setTagSearch}
				tagSearch={tagSearch}
				selectedTags={selectedTags}
				handleTagChange={handleTagChange}
				applyTags={applyTags}
				createTag={createTag}
				assignWorkflow={assignWorkflow}
				selectedRecords={selectedRecords}
				checkedTags={checkedTags}
				isAssignDisabled={isAssignDisabled}
			/>
			<OnboardedClientsTable
				clients={data?.clients || []}
				meta={data?.meta}
				isLoading={isLoading}
				pagination={pagination}
				setPagination={setPagination}
				selectedRecords={selectedRecords}
				setSelectedRecords={setSelectedRecords}
				setRowSelection={setRowSelection}
				rowSelection={rowSelection}
			/>
		</Fragment>
	)
}

export default OnboardedClientsContainer
