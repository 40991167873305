import { Fragment } from 'react'
import { Button } from 'theme/ui/forms'
import CoApplicantDropdown from '../co-applicant-dropdown'

const CoApplicantField = ({
	applicants,
	formValues,
	isDisabled,
	arrayHelpers,
	client_applicants,
}) => {
	return (
		<Fragment>
			{applicants.length > 0 ? (
				<Fragment>
					<div className='mb-3 flex items-center justify-between'>
						<div>
							<h4 className='m-0 text-base font-bold'>Co Applicants</h4>
							<p className='text-sm text-gray-500'>
								You can include up to{' '}
								<span className='font-bold text-black'>
									{formValues.userCount - 1}
								</span>{' '}
								Co applicants
							</p>
						</div>
						<Button
							type='button'
							size='xs'
							variant='primary-outline'
							disabled={isDisabled}
							onClick={() => arrayHelpers.push(null)}>
							<i className='uil-plus' />
							Add More
						</Button>
					</div>
					<div className='flex flex-col space-y-6'>
						{applicants.map((_, idx) => (
							<div key={idx} className='flex flex-col space-y-6'>
								<div className='flex items-start space-x-2'>
									<CoApplicantDropdown
										label={`Select Co Applicant ${idx + 1}`}
										name={`co_applicants[${idx}].user`}
										key={idx}
										formValues={formValues}
										client_applicants={client_applicants}
									/>
									<div className='!pt-[31px]'>
										<Button
											type='button'
											size='sm'
											variant='danger-outline'
											onClick={() => arrayHelpers.remove(idx)}>
											<i className='uil-trash-alt' />
										</Button>
									</div>
								</div>
								{/* <ScreenTypeField
									name={`co_applicants[${idx}].screen_type`}
									label={`Co Applicant ${idx + 1}`}
								/> */}
							</div>
						))}
					</div>
				</Fragment>
			) : (
				<p className='mb-0 text-center text-gray-500'>
					Client{' '}
					<span
						className='cursor-pointer font-bold text-main hover:underline'
						onClick={() => arrayHelpers.push(null)}>
						here
					</span>{' '}
					to add co applicant
				</p>
			)}
		</Fragment>
	)
}

export default CoApplicantField
