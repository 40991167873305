import { Fragment, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import classNames from 'classnames'
import { useAppRouter } from 'hooks'
import { AssignedWorkflowForm, PageHeader } from 'modules/assigned-workflows'
import { useLazyGetAssignedWorkflowQuery } from 'modules/assigned-workflows/assigned-workflow-apis'
import {
	getDocumentBoundFields,
	populateField,
} from 'modules/assigned-workflows/assigned-workflow-form/helpers'
import AssignedWorkflowSignature from 'modules/assigned-workflows/assigned-workflow-signature'
import { getWorkflowCardBg } from 'modules/assigned-workflows/assigned-workflows-listing/workflow-card'
import StatusBadge from 'modules/assigned-workflows/assigned-workflows-listing/workflow-card/status-badge'
import { createTabs, getWorkflowUrl } from 'modules/assigned-workflows/helpers'
import { DocumentViewerProvider } from 'pages/Authentication/profile/components/workflow/document-viewer-provider'
import StatusInformation from 'pages/Authentication/profile/components/workflow/status-information'
import {
	getFieldsByUser,
	getFieldsList,
} from 'pages/Authentication/profile/components/workflow/utils'
import Pages404 from 'pages/StaticPages/Page404'
import UnAuthorize from 'pages/StaticPages/UnAuthorize'
import { useMediaQuery } from 'react-responsive'
import AppLayout from 'theme/layouts/app-layout'
import Spinner from 'theme/ui/spinner'
import { WorkflowStatuses } from 'utilities/helpers'
import hasPermission from 'utils/checkPermission'

const AssignedWorkflowManage = () => {
	const { params, navigate } = useAppRouter()
	const [isLoading, setIsLoading] = useState(false)
	const hasReadPermission = hasPermission('clientWorkflow:read')
	const id = params?.id
	const { defaultGroup } = useSelector((state) => state.Permissions)
	const URL = useMemo(() => getWorkflowUrl(defaultGroup, id), [defaultGroup, id])
	const isDesktop = useMediaQuery({
		query: '(min-width: 768px)',
	})

	const [fetchWorkflow, { isUninitialized, data }] = useLazyGetAssignedWorkflowQuery()

	useEffect(() => {
		const fetchDate = async (URL) => {
			setIsLoading(true)
			const response = await fetchWorkflow(URL)
			const workflow = response?.data?.data?.workflow
			const uploads = response?.data?.data?.uploads
			const tabs = createTabs(workflow, uploads)
			const activeTab = tabs.find((tab) => {
				if (workflow?.status === WorkflowStatuses?.REJECTED) {
					return tab?.isRejected === true
				} else {
					return tab?.isCompleted === false
				}
			})
			const $activeTab = activeTab ? activeTab : tabs[tabs?.length - 1]
			const nextStepId = $activeTab?.id + 1
			navigate(`${window.location.pathname}?step=${nextStepId}`)
			setIsLoading(false)
		}
		if (URL) {
			fetchDate(URL)
		}
	}, [URL])

	const {
		data: {
			signPriority,
			workflow,
			uploads,
			individualDocument,
			createdBy,
			isAllDocumentsSigned,
		},
		signature_status,
	} = useMemo(() => data || { data: {} }, [data])
	const user_type = workflow?.user_type

	const fieldsArray = useMemo(
		() =>
			getFieldsList(individualDocument?.documents).filter(
				(field) => field?.is_document_bound !== true,
			),
		[individualDocument],
	)

	const profileFields = useMemo(
		() => getFieldsByUser(fieldsArray, user_type),
		[fieldsArray, user_type],
	)
	const documentBoundFields = useMemo(
		() => getDocumentBoundFields(individualDocument?.documents, user_type),
		[individualDocument?.documents, user_type],
	)
	const tab2InitialValues = useMemo(
		() => ({
			fields: profileFields.map((field) => populateField(field)),
			document_bound_fields: documentBoundFields.map((f) => ({
				fields: Array.isArray(f?.fields)
					? f?.fields.map((field) => populateField(field))
					: [],
			})),
		}),
		[profileFields, documentBoundFields],
	)

	const colors = useMemo(() => getWorkflowCardBg(workflow?.status), [workflow?.status])

	return (
		<AppLayout
			meta={{
				title: 'Fill Assigned Workflows',
			}}
			variant='client'>
			{hasReadPermission ? (
				<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
					{isLoading || isUninitialized ? (
						<Spinner />
					) : (
						<Fragment>
							{workflow ? (
								<Fragment>
									<div className='page-container'>
										<PageHeader
											workflow={workflow}
											title={workflow?.title}
											hasBackButton={true}>
											{colors ? (
												<StatusBadge
													status={workflow?.status}
													color={colors?.color}
													bgColor={colors?.backgroundColor}
												/>
											) : undefined}
										</PageHeader>
									</div>
									<div
										className={classNames(
											isDesktop ? 'page-body-client' : 'page-body',
										)}>
										{[
											WorkflowStatuses.COMPLETED,
											WorkflowStatuses.APPROVED,
											WorkflowStatuses.FINAL_REVIEWER_APPROVED,
											WorkflowStatuses.GATHERING_SIGNATURE,
										].includes(workflow?.status) ? (
											<div>
												{workflow?.status ===
												WorkflowStatuses.GATHERING_SIGNATURE ? (
													<DocumentViewerProvider>
														<AssignedWorkflowSignature
															workflow={workflow}
															signature_status={signature_status}
															isAvailableForSignature={signPriority}
														/>
													</DocumentViewerProvider>
												) : (
													<StatusInformation
														isAllDocumentsSigned={isAllDocumentsSigned}
														status={workflow?.status}
														workflow={workflow}
														uploads={uploads}
														individualDocument={individualDocument}
													/>
												)}
											</div>
										) : (
											<AssignedWorkflowForm
												workflow={workflow}
												uploads={uploads}
												createdBy={createdBy}
												individualDocument={individualDocument}
												tab2InitialValues={tab2InitialValues}
											/>
										)}
									</div>
								</Fragment>
							) : (
								<Pages404 />
							)}
						</Fragment>
					)}
				</div>
			) : (
				<UnAuthorize />
			)}
		</AppLayout>
	)
}

export default AssignedWorkflowManage
