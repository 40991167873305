import { Field, FormikProvider, useFormik } from 'formik'
import { Button, InputField, PhoneNumberField } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { useUpdateClientInvitationMutation } from '../invited-client-api'

const PersonalInfoForm = ({ client }) => {
	const [updateClient] = useUpdateClientInvitationMutation()

	const formik = useFormik({
		initialValues: {
			first_name: client?.first_name,
			last_name: client?.last_name,
			email: client?.email,
			phone_number: client?.phone_number || '',
		},
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true)
				const payload = { ...values }
				delete payload.email
				const response = await updateClient({ payload, id: client?.id })
				if (response?.data?.status === 200) {
					notification('success', 'Client details updated successfully')
				}
				setSubmitting(false)
			} catch (error) {
				setSubmitting(false)
			}
		},
	})

	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				<div className='mb-4 rounded-lg bg-white'>
					<div className='bg-[#F2F5FF] p-4 py-3'>
						<h5 className='font-bold'>Personal Information</h5>
					</div>
					<div className='grid grid-cols-1 divide-y'>
						<div className='grid grid-cols-2 items-center gap-3 px-4 py-2'>
							<span className='text-gray-500'>First Name</span>
							<Field name='first_name' component={InputField} />
						</div>
						<div className='grid grid-cols-2 items-center gap-3 px-4 py-2'>
							<span className='text-gray-500'>Last Name</span>
							<Field name='last_name' component={InputField} />
						</div>
						<div className='grid grid-cols-2 items-center gap-3 px-4 py-2'>
							<span className='text-gray-500'>Contact Phone</span>
							<Field name='phone_number' component={PhoneNumberField} />
						</div>
						<div className='grid grid-cols-2 items-center gap-3 px-4 py-2'>
							<span className='text-gray-500'>Email</span>
							<Field name='email' component={InputField} disabled />
						</div>
					</div>

					<div className='mx-auto p-3 text-center'>
						<Button
							type='submit'
							isLoading={formik.isSubmitting}
							isLoadingText='Updating...'>
							Update
						</Button>
					</div>
				</div>
			</form>
		</FormikProvider>
	)
}

export default PersonalInfoForm
