import { Fragment, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import { useLazyGetInvitedClientsQuery } from '../invited-client-api'
import BulkActions from './bulk-actions'
import Filters from './filters'
import InvitedClientsTable from './invited-clients-table'

const InvitedClientsContainer = () => {
	const location = useLocation()
	const history = useHistory()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination
	const [rowSelection, setRowSelection] = useState({})
	const $selectedRows = useMemo(() => Object.keys(rowSelection).map(Number), [rowSelection])

	useEffect(() => {
		if (pagination) {
			const parseQueryString = getParseQueryString(location?.search)
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			history.push(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	const [fetchClients, { data, isLoading }] = useLazyGetInvitedClientsQuery()

	useEffect(() => {
		if (location?.search) {
			fetchClients({ ...getParseQueryString(location?.search), role: 'client' })
		}
	}, [location?.search])

	return (
		<Fragment>
			{$selectedRows.length > 0 ? <BulkActions selectedRows={$selectedRows} /> : undefined}
			<Filters />
			<InvitedClientsTable
				clients={data?.clients || []}
				isLoading={isLoading}
				meta={data?.meta}
				pagination={pagination}
				setPagination={setPagination}
				setRowSelection={setRowSelection}
				rowSelection={rowSelection}
			/>
		</Fragment>
	)
}

export default InvitedClientsContainer
