import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
import { routes } from '../..'
import DeleteConfirmAlert from './delete-confirm-alert'

const Actions = ({ workflow, last_updated, isActionActive }) => {
	const [isOpen, setIsOpen] = useState(false)
	const onClose = () => setIsOpen(false)

	const ID = workflow?.id
	const option = useMemo(() => ({ id: workflow?.id, title: workflow?.title }), [workflow])

	return (
		<div className='w-[250px]'>
			<DeleteConfirmAlert isOpen={isOpen} onClose={onClose} id={ID} />
			{isActionActive &&
			(isAllowTo(permissions?.workflowTemplate?.edit) ||
				isAllowTo(permissions?.workflowTemplate?.assign) ||
				isAllowTo(permissions?.workflowTemplate?.delete)) ? (
				<div className='flex space-x-2'>
					{isAllowTo(permissions?.workflowTemplate?.edit) ? (
						<Link to={routes.edit(ID)} className='action-btn btn-gray-outline'>
							<i className='fas fa-edit' style={{ fontSize: 15 }} />
							<span>Edit</span>
						</Link>
					) : undefined}

					{isAllowTo(permissions?.workflowTemplate?.assign) ? (
						<Link to={routes.assign(option)} className='action-btn btn-gray'>
							<i className='uil-clipboard-notes' style={{ fontSize: 17 }} />
							<span>Assign</span>
						</Link>
					) : undefined}

					{isAllowTo(permissions?.workflowTemplate?.delete) ? (
						<button
							type='button'
							className='action-btn btn-red-outline'
							onClick={() => setIsOpen(true)}>
							<i className='uil-trash-alt' style={{ fontSize: 16 }} />
							<span>Delete</span>
						</button>
					) : undefined}
				</div>
			) : (
				<span className=' text-sm  !text-gray-700'>{last_updated}</span>
			)}
		</div>
	)
}

export default Actions
