import { configureStore } from '@reduxjs/toolkit'

import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import { assignedWorkflowApi } from 'modules/assigned-workflows/assigned-workflow-apis'
import { authApi } from 'modules/authentication/auth-api'
import { clientApi } from 'modules/clients/client-apis'
import { documentEventLogApi } from 'modules/document-event-log/document-event-log-api'
import { deletedDocumentApi } from 'modules/documents/deleted-document-api'
import { documentApi } from 'modules/documents/document-api'
import { documentPackageApi } from 'modules/documents/document-package-api'
import { documentVersionApi } from 'modules/documents/document-versions/document-version-api'
import { emailTemplatesApi } from 'modules/email-templates/email-templates-api'
import { fileApi } from 'modules/files/file-apis'
import { noteApi } from 'modules/notes/notes-api'
import { notificationsTemplatesApi } from 'modules/notification-templates/notifications-templates-api'
import { notificationApi } from 'modules/notifications/notification-api'
import { paymentsApi } from 'modules/payments/payments-api'
import { roleApi } from 'modules/roles/roles-api'
import { settingsApi } from 'modules/settings/settings-api'
import { featuresApi } from 'modules/subscriptions/features/features-api'
import { subscriptionApi } from 'modules/subscriptions/subscriptions-api'
import { userSubscriptionApi } from 'modules/subscriptions/user-subscriptions-api'
import { systemFieldApi } from 'modules/system-fields/system-fields-api'
import { systemSettingApi } from 'modules/system-settings/system-settings-api'
import { teamMemberApi } from 'modules/team-members/team-members-api'
import { teamApi } from 'modules/teams/teams-api'
import { usersApi } from 'modules/users/users-api'
import { workflowCommentApi } from 'modules/workflows/workflow-comment-section/workflow-comment-api'
import { workflowFinalReviewApi } from 'modules/workflows/workflow-final-reviews/workflow-final-reviews-apis'
import { workflowTemplateApi } from 'modules/workflows/workflow-template/workflow-template-apis'
import { workflowOutstandingApi } from 'modules/workflows/workflows-outstandings/workflow-outstandings-apis'
import { userProfileApi } from 'pages/Authentication/profile/components/profile/user-profile-api'

import { invitedClientApi } from 'modules/clients/invited-client-api'
import { onboardingApi } from 'modules/onborading/onborading-api'
import rootReducer from './reducers'

const authPersistConfig = {
	key: 'auth',
	storage: storage,
	whitelist: ['Permissions', 'jwtauth', 'clientOnboarding'],
}

const persistedReducer = persistReducer(authPersistConfig, rootReducer)

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat([
			clientApi.middleware,
			workflowTemplateApi.middleware,
			workflowOutstandingApi.middleware,
			workflowFinalReviewApi.middleware,
			assignedWorkflowApi.middleware,
			documentApi.middleware,
			deletedDocumentApi.middleware,
			documentPackageApi.middleware,
			documentEventLogApi.middleware,
			noteApi.middleware,
			userProfileApi.middleware,
			fileApi.middleware,
			systemFieldApi.middleware,
			systemSettingApi.middleware,
			authApi.middleware,
			subscriptionApi.middleware,
			paymentsApi.middleware,
			notificationApi.middleware,
			featuresApi.middleware,
			teamApi.middleware,
			roleApi.middleware,
			usersApi.middleware,
			settingsApi.middleware,
			userSubscriptionApi.middleware,
			teamMemberApi.middleware,
			workflowCommentApi.middleware,
			emailTemplatesApi.middleware,
			notificationsTemplatesApi.middleware,
			documentVersionApi.middleware,
			onboardingApi.middleware,
			invitedClientApi.middleware,
			thunk,
		]),
	devTools: true,
})

export const { dispatch } = store
export const persistor = persistStore(store)
export default store
