import { Fragment } from 'react'

import AddressInfoForm from './address-info-form'
import DocumentUploadForm from './document-upload-form/document-upload-form'
import PersonalInfoForm from './personal-info-form'

const InvitedClientForm = ({ client }) => {
	return (
		<Fragment>
			<PersonalInfoForm client={client} />
			<AddressInfoForm client={client} />
			<DocumentUploadForm client={client} />
		</Fragment>
	)
}

export default InvitedClientForm
