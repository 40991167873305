import { createColumnHelper } from '@tanstack/react-table'
import { useState } from 'react'

import DataTable, { IndeterminateCheckbox } from 'theme/ui/data-table'
import StyledBadge from 'theme/ui/styled-badge'
import { statusColor } from '.'
import Actions from './actions'

const InvitedClientsTable = ({
	clients,
	meta,
	isLoading,
	pagination,
	setPagination,
	rowSelection,
	setRowSelection,
}) => {
	const [actionActiveId, setActionActiveId] = useState(null)
	const [sorting, setSorting] = useState([])
	const onMouseEnter = (id) => setActionActiveId(id)
	const onMouseLeave = () => setActionActiveId(null)

	const columnHelper = createColumnHelper()
	const columns = [
		{
			id: 'select',
			size: 20,
			header: ({ table }) => (
				<IndeterminateCheckbox
					{...{
						checked: table.getIsAllRowsSelected(),
						indeterminate: table.getIsSomeRowsSelected(),
						onChange: table.getToggleAllRowsSelectedHandler(),
					}}
				/>
			),
			cell: ({ row }) => (
				<div className='px-1'>
					<IndeterminateCheckbox
						{...{
							checked: row.getIsSelected(),
							disabled: !row.getCanSelect(),
							indeterminate: row.getIsSomeSelected(),
							onChange: row.getToggleSelectedHandler(),
						}}
					/>
				</div>
			),
		},

		columnHelper.accessor((row) => row, {
			id: 'name',
			header: () => <span>Name</span>,
			cell: (info) => {
				return (
					<span className='line-clamp-1 max-w-[180px] text-sm font-bold !text-gray-700'>
						{info.getValue()?.full_name}
					</span>
				)
			},
		}),

		columnHelper.accessor('email', {
			header: () => <span>Email</span>,
			cell: (info) => <span>{info.getValue()}</span>,
		}),

		columnHelper.accessor('status', {
			header: () => <span>Status</span>,
			size: 90,
			cell: (info) => (
				<div className='inline-block'>
					<StyledBadge color={statusColor[info.getValue()]}>
						{info.getValue()}
					</StyledBadge>
				</div>
			),
		}),

		columnHelper.accessor((row) => row, {
			id: 'invited_at',
			size: 270,
			header: () => <span>Invited At</span>,
			cell: (info) => (
				<Actions
					id={info.getValue()?.id}
					user={info.getValue()}
					created_at={info.getValue()?.invited_at}
					isActionActive={actionActiveId === info?.row?.id}
				/>
			),
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={clients}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			setSorting={setSorting}
			sorting={sorting}
			meta={meta}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			setRowSelection={setRowSelection}
			rowSelection={rowSelection}
			isRowSelection={true}
		/>
	)
}

export default InvitedClientsTable
