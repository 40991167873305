import blueBg from 'assets/svg/workflow-blue-bg.svg'
import greenBg from 'assets/svg/workflow-green-bg.svg'
import purpleBg from 'assets/svg/workflow-purple-bg.svg'
import yellowBg from 'assets/svg/workflow-yellow-bg.svg'
import classNames from 'classnames'
import { snakeCase } from 'lodash'
import { WorkflowStatuses } from 'utilities/helpers'
import WorkflowCard from './workflow-card'

export const workflowCardBackgrounds = [
	{
		statuses: ['assigned'],
		backgroundImage: purpleBg,
		color: classNames('text-[#8D65DE]'),
		backgroundColor: classNames('bg-[#EDE9F5]'),
		colorName: 'purple',
	},
	{
		statuses: ['in_review', 'in_final_review'],
		backgroundImage: blueBg,
		color: classNames('text-[#1E90FF]'),
		backgroundColor: classNames('bg-[#E2EDF9]'),
		colorName: 'blue',
	},
	{
		statuses: ['rejected', 'final_reviewer_rejected', 'cancelled', 'draft'],
		backgroundImage: yellowBg,
		color: classNames('text-[#F1B44C]'),
		backgroundColor: classNames('bg-[#FAEEDA]'),
		colorName: 'yellow',
	},
	{
		statuses: ['approved', 'gathering_signatures', 'final_reviewer_approved', 'completed'],
		backgroundImage: greenBg,
		color: classNames('text-[#228B22]'),
		backgroundColor: classNames('bg-[#E8F3E8]'),
		colorName: 'green',
	},
]

export const getWorkflowCardBg = (status) => {
	const $status = snakeCase(status)
	return workflowCardBackgrounds.find((wbg) => wbg.statuses.includes($status))
}

export const getWorkflowLinkTitle = (status) => {
	if (status === WorkflowStatuses?.GATHERING_SIGNATURE) {
		return 'Sign Now'
	}

	if (status === WorkflowStatuses?.DRAFT) {
		return 'Resume'
	}

	if (status === WorkflowStatuses?.COMPLETED || status === WorkflowStatuses?.APPROVED) {
		return 'View'
	}

	return 'Initiate Workflow'
}

export default WorkflowCard
