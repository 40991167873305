import { Field, FormikProvider, useFormik } from 'formik'
import { useState } from 'react'
import { Button, OtpField } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { useUpdateClientInvitationMutation } from '../../invited-client-api'
import FileUploadCard from './file-upload-card'

const DocumentUploadForm = ({ client }) => {
	const [updateClient] = useUpdateClientInvitationMutation()
	const [validDocumentId, setValidDocumentId] = useState(null)
	const [governmentDocumentId, setGovernmentDocumentId] = useState(null)

	const formik = useFormik({
		initialValues: {
			sin_number: client?.sin_number || '',
		},
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true)
				const payload = { ...values }
				if (validDocumentId) {
					Object.assign(payload, {
						valid_document: validDocumentId,
					})
				}
				if (governmentDocumentId) {
					Object.assign(payload, {
						government_document: governmentDocumentId,
					})
				}

				const response = await updateClient({ payload, id: client?.id })
				if (response?.data?.status === 200) {
					notification('success', 'Client details updated successfully')
				}
				setSubmitting(false)
			} catch (error) {
				setSubmitting(false)
			}
		},
	})

	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				<div className='mb-4 rounded-lg bg-white'>
					<div className='bg-[#F2F5FF] p-4 py-3'>
						<h5 className='font-bold'>Documents</h5>
					</div>
					<div className='grid grid-cols-1 divide-y'>
						<div className='flex items-center justify-between gap-3 px-4 py-2'>
							<span className='text-gray-500'>SIN</span>
							<Field name='sin_number' component={OtpField} otpLength={9} />
						</div>
						<div className='grid grid-cols-2 items-center gap-3 px-4 py-2'>
							<span className='text-gray-500'>Government Document</span>
							<FileUploadCard
								title='Government Document'
								file={client?.government_document}
								onSetFileId={(id) => setGovernmentDocumentId(id)}
							/>
						</div>

						<div className='grid grid-cols-2 items-center gap-3 px-4 py-2'>
							<span className='text-gray-500'>Valid Id</span>
							<FileUploadCard
								title='Valid ID'
								file={client?.valid_document}
								onSetFileId={(id) => setValidDocumentId(id)}
							/>
						</div>
					</div>

					<div className='mx-auto p-3 text-center'>
						<Button
							type='submit'
							isLoading={formik.isSubmitting}
							// disabled={isUploading}
							isLoadingText='Updating...'>
							Update
						</Button>
					</div>
				</div>
			</form>
		</FormikProvider>
	)
}

export default DocumentUploadForm
