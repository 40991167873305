import { Fragment, useState } from 'react'
import { Button } from 'theme/ui/forms'
import OnboardPopup from './onboard-popup/onboard-popup'

const BulkActions = ({ selectedRows }) => {
	const [isOpen, setIsOpen] = useState(false)
	const onOpen = () => setIsOpen(true)
	const onClose = () => setIsOpen(false)

	return (
		<Fragment>
			<OnboardPopup onClose={onClose} isOpen={isOpen} selectedRows={selectedRows} />
			<div className='mb-3 flex items-start justify-between'>
				<span className='text-sm font-semibold'>{selectedRows.length} Items selected</span>
				<div className=' flex items-start justify-end'>
					<Button type='button' size='sm' variant='primary-outline' onClick={onOpen}>
						Onboard Now
					</Button>
				</div>
			</div>
		</Fragment>
	)
}

export default BulkActions
