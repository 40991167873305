import { Fragment } from 'react'
import { TabContent, TabPane } from 'reactstrap'

import InvitedClientForm from 'modules/clients/invited-client-form'
import Documents from './Documents'
import Notes from './Notes'
import WorkflowFiles from './WorkflowFiles'
import Workflows from './Workflows'
import Info from './info'
import ClientInfo from './info/ClientInfo'

const Tabs = ({ client, role, activeTab, isEditMode }) => {
	return (
		<Fragment>
			<TabContent activeTab={activeTab}>
				<TabPane tabId='1'>
					{isEditMode ? (
						<InvitedClientForm client={client} />
					) : (
						<Fragment>
							<ClientInfo client={client} />
							<Info
								activeTab={activeTab}
								client={client}
								role={role}
								isEditMode={isEditMode}
							/>
						</Fragment>
					)}
				</TabPane>

				{!isEditMode ? (
					<Fragment>
						<TabPane tabId='2'>
							<Documents activeTab={activeTab} client={client} role={role} />
						</TabPane>

						<TabPane tabId='3'>
							<Workflows activeTab={activeTab} client={client} role={role} />
						</TabPane>
						<TabPane tabId='4'>
							<WorkflowFiles activeTab={activeTab} client={client} role={role} />
						</TabPane>
						<TabPane tabId='5'>
							<Notes activeTab={activeTab} client={client} role={role} />
						</TabPane>
					</Fragment>
				) : undefined}
			</TabContent>
		</Fragment>
	)
}

export default Tabs
