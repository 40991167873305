import { createApi } from '@reduxjs/toolkit/query/react'

import apiEndpoints from 'helpers/apiEndpoints'
import axiosBaseQuery from 'store/axios-base-query'
import { formatDate } from 'utilities/helpers'
import { formatBytes } from 'utils/commonFunctions'
import { CLIENT_API_ROUTES } from './routes'

export const clientApi = createApi({
	reducerPath: 'Client',
	refetchOnFocus: true,
	keepUnusedDataFor: 0,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['Client', 'InvitedClient'],
	endpoints: (builder) => ({
		getClients: builder.query({
			query: (params) => ({
				url: CLIENT_API_ROUTES.findAll(),
				method: 'GET',
				params: params,
			}),
			providesTags: ['Client'],
			transformResponse: (response, _, arg) => {
				const clients = Array.isArray(response?.data?.clients)
					? response?.data?.clients.map((client, idx) => {
							const $client = client?.user
							return {
								index: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
								id: $client?.id,
								full_name: `${$client?.first_name} ${$client?.last_name}`,
								phone_number: $client?.phone_number,
								email: $client?.email,
								role: $client?.role,
								tags: Array.isArray($client?.tags)
									? $client?.tags[0]?.tag?.split(', ')
									: [],
								tagStr: $client?.tags[0]?.tag,
								updated_at: formatDate($client?.created_at),
							}
					  })
					: []

				return {
					clients,
					meta: response?.data?.meta,
				}
			},
		}),
		getInvitedClients: builder.query({
			query: (params) => ({
				url: CLIENT_API_ROUTES.findAllInvites(),
				method: 'GET',
				params: { ...params, roleName: 'client' },
			}),
			keepUnusedDataFor: 0,
			providesTags: ['InvitedClient'],
			transformResponse: (response, _, arg) => {
				const clients = Array.isArray(response?.data?.users)
					? response?.data?.users.map((client, idx) => {
							const user = client?.user
							return {
								id: user?.id,
								index: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
								full_name: `${user?.first_name} ${user?.last_name}`,
								email: user?.email,
								first_name: user?.first_name,
								last_name: user?.last_name,
								status: client?.status,
								group: client?.group,
								created_at: formatDate(user?.created_at),
								expired_at: formatDate(user?.invitation_expires_at),
							}
					  })
					: []

				return {
					clients,
					meta: response?.data?.meta,
				}
			},
		}),
		getOneClient: builder.query({
			keepUnusedDataFor: 0,
			query: (id) => ({
				url: CLIENT_API_ROUTES.findOne(id),
				method: 'GET',
			}),
			invalidatesTags: ['Client'],
			transformResponse: (response) => ({
				client: response?.client,
			}),
		}),
		getClientDetails: builder.query({
			query: (id) => ({
				url: `${apiEndpoints.clients}/${id}`,
				method: 'GET',
			}),
			invalidatesTags: ['Client'],
			transformResponse: (response) => {
				const role = response?.client?.teams.length > 0 ? ' advisor' : ''
				return {
					client: response?.client,
					role,
				}
			},
		}),
		deleteClientDetails: builder.mutation({
			query: (id) => ({
				url: `${apiEndpoints.users}/delete/client-data/${id}`,
				method: 'POST',
			}),
			invalidatesTags: ['Client'],
		}),
		getTags: builder.query({
			query: () => ({
				url: `${apiEndpoints.tags.list}`,
				method: 'GET',
			}),
			providesTags: ['Client'],
			transformResponse: (response) => {
				const tagsData = response?.tags
				return {
					tagsData,
				}
			},
		}),
		assignTags: builder.mutation({
			query: (payload) => ({
				url: `${apiEndpoints.tags.assign}`,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['Client'],
		}),
		uploadProfilePicture: builder.mutation({
			query: (payload) => ({
				url: apiEndpoints.file,
				method: 'POST',
				data: payload,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}),
			invalidatesTags: ['Client'],
		}),
		updateClientProfile: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${apiEndpoints.user.profile}/${id}/`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['Client'],
		}),
		getQueryData: builder.query({
			keepUnusedDataFor: 0,
			query: (url) => ({
				url: url,
				method: 'GET',
			}),
			invalidatesTags: ['Client'],
		}),
		clientSettingSave: builder.mutation({
			query: (payload) => ({
				url: `${apiEndpoints.clientSetting}/save`,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['Client'],
		}),
		createNote: builder.mutation({
			query: (payload) => ({
				url: apiEndpoints.notes,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['Client'],
		}),
		deleteClientSetting: builder.mutation({
			query: (id) => ({
				url: `${apiEndpoints.clientSetting}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Client'],
		}),
		deleteClient: builder.mutation({
			query: (id) => ({
				url: CLIENT_API_ROUTES.destroy(id),
				method: 'POST',
			}),
			invalidatesTags: ['Client'],
		}),
		getWorkflowFiles: builder.query({
			keepUnusedDataFor: 0,
			query: ({ params, url }) => ({
				url: url,
				method: 'GET',
				params: params,
			}),
			providesTags: ['Client'],
			transformResponse: (response, _, arg) => {
				const data = Array.isArray(response?.data?.workflowFiles)
					? response?.data?.workflowFiles.map((file, idx) => ({
							index:
								parseInt(arg?.params?.limit) * (parseInt(arg?.params?.page) - 1) +
								idx +
								1,
							id: file?.id,
							title: file?.title,
							file: file?.files,
							file_size: formatBytes(file?.files?.size),
							created_at: formatDate(file?.files?.created_at),
					  }))
					: []

				return {
					data,
					meta: response?.data?.meta,
				}
			},
		}),
		sendClientInvitation: builder.mutation({
			query: (payload) => ({
				url: 'users/invitation',
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['InvitedClient'],
		}),

		getLoginHistory: builder.query({
			query: (id) => ({
				url: `users/profiles/login-history/${id}`,
				method: 'GET',
			}),
		}),
	}),
})

export const {
	useLazyGetClientsQuery,
	useLazyGetInvitedClientsQuery,
	useLazyGetOneClientQuery,
	useDeleteClientMutation,
	useLazyGetClientDetailsQuery,
	useDeleteClientDetailsMutation,
	useUploadProfilePictureMutation,
	useUpdateClientProfileMutation,
	useGetQueryDataQuery,
	useLazyGetQueryDataQuery,
	useClientSettingSaveMutation,
	useCreateNoteMutation,
	useDeleteClientSettingMutation,
	useLazyGetWorkflowFilesQuery,
	useSendClientInvitationMutation,
	useAssignTagsMutation,
	useLazyGetTagsQuery,
	useLazyGetLoginHistoryQuery,
} = clientApi
