import { useEffect, useMemo, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { BarLoader } from 'react-spinners'

import { SpinLoader } from 'components/Common'
import { useUser, useWindowSize } from 'hooks'
import ClientInvitation from 'modules/dashboard/client-invitation'
import { useLazyGetTeamDetailsQuery } from 'modules/teams'
import NoSubscription from 'pages/StaticPages/NoSubscription'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { userRoutes } from 'routes/allRoutes'
import { matchRoutes } from 'routes/matchRoutes'

import { useErrorBoundary } from 'react-error-boundary'
import { useMediaQuery } from 'react-responsive'
import { authorizeRequest } from '.'
import Header from './header'
import Sidebar from './sidebar'

const AppLayout = ({ children, meta, variant }) => {
	const metaTitle = meta?.title
	const metaKeywords = meta?.keywords
	const metaDescription = meta?.description
	const { team, isSuperAdmin, permissions } = useUser()
	const [fetchTeam, { data, isLoading: apiIsLoading, isSuccess }] = useLazyGetTeamDetailsQuery()
	const [isLoading, setIsLoading] = useState(true)
	const { width } = useWindowSize()
	const location = useLocation()
	const { showBoundary } = useErrorBoundary()
	const isDesktop = useMediaQuery({
		query: '(min-width: 768px)',
	})

	useEffect(() => {
		if (team && !isSuperAdmin) {
			fetchTeam(team?.id).finally(() => setIsLoading(false))
		} else {
			setIsLoading(false)
		}
	}, [team, isSuperAdmin, fetchTeam])

	const { payments, $team } = useMemo(() => {
		return {
			$team: data?.data?.team,
			payments: data?.data?.payments || [],
		}
	}, [data])

	if (isSuccess && $team?.status === 0) {
		return <NoSubscription team={$team} payments={payments} />
	}

	const isLoadingVisible = isLoading || apiIsLoading
	const [{ route }] = matchRoutes(userRoutes, location?.pathname)

	useEffect(() => {
		authorizeRequest(permissions, route)
			.then(() => {})
			.catch((error) => {
				showBoundary(error)
			})
	}, [route, permissions])

	useEffect(() => {
		if (variant === 'client' && isDesktop) {
			document.body.className = 'bg-white'
		}
		return () => {
			document.body.className = ''
		}
	}, [variant, isDesktop])

	return (
		<HelmetProvider>
			<Helmet>
				{metaTitle ? <title>{metaTitle} | Experivise</title> : <title>Experivise</title>}
				{metaKeywords ? <meta name='keywords' content={metaKeywords} /> : undefined}
				{metaDescription ? (
					<meta name='description' content={metaDescription} />
				) : undefined}
			</Helmet>

			<div id='layout-wrapper'>
				<>
					<Header />
					<Sidebar />
					{isLoadingVisible ? (
						<>
							<div className='fixed z-[1007]'>
								<BarLoader color='#5b73e8' width={width - 17} />
							</div>
							<div className='main-content flex min-h-[400px] items-center justify-center'>
								<SpinLoader />
							</div>
						</>
					) : (
						<div className='main-content'>
							<ClientInvitation />
							{children}
						</div>
					)}
				</>
			</div>
		</HelmetProvider>
	)
}

AppLayout.defaultProps = {
	variant: 'admin',
}

export default AppLayout
