/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
// import { getModuleName } from '.'
import apiEndpoints from '../../../../helpers/apiEndpoints'
import * as actions from '../../../../store/common/actions'

const LoginHistory = ({ client }) => {
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const dispatch = useDispatch()

	const getLoginHistory = () => {
		setLoading(true)
		dispatch(actions.all(`${apiEndpoints.clients}/login-history/${client?.id}?page=1&limit=5`))
			.then((response) => {
				setLoading(false)
				if (response?.status === 200 && Array.isArray(response?.userLoginHistory)) {
					const [data] = response.userLoginHistory
					setData(data)
				}
			})
			.catch(() => {
				setLoading(false)
			})
	}

	useEffect(() => {
		if (client?.id) {
			getLoginHistory()
		}
	}, [client])

	return (
		<Fragment>
			<div className='card !rounded-lg shadow-none'>
				<div className='card-header !border-none !bg-[#F2F5FF] p-4 py-3'>
					<h5 className='mb-0 font-bold'>Login History</h5>
				</div>
				<div className='card-body p-0'>
					{loading ? (
						<div className='d-flex justify-content-center align-items-center py-3'>
							<div className='spinner-border mr-2 !h-5 !w-5' /> &nbsp;Loading...
						</div>
					) : (
						<Fragment>
							{data.length > 0 ? (
								data.map((history, idx) => {
									// const moduleNames = getModuleName(history?.body)

									return (
										<Fragment key={idx}>
											<div className='flex rounded border-b px-3 py-3 last:border-none'>
												{/* <p className='text-gray mb-0'>
													{client?.first_name} signed in{' '}
													{moment(history.login_at).format('hh:mm A')},{' '}
													{moment(history.login_at).format('MMM D YYYY')}{' '}
													and viewed the {moduleNames} page
												</p> */}
												<strong className='w-[50px]'>{idx + 1}.</strong>
												<span>
													{moment(history.login_at).format(
														'MMMM DD, yy, hh:mm A',
													)}
												</span>
											</div>
										</Fragment>
									)
								})
							) : (
								<Fragment>
									<p className='text-center'>No login history found</p>
								</Fragment>
							)}
						</Fragment>
					)}
				</div>
			</div>
		</Fragment>
	)
}

export default LoginHistory
