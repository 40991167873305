import InvitedClientsContainer from './invited-clients-container'

export const statusColor = {
	Pending: 'warning',
	Approved: 'success',
	Rejected: 'danger',
	Submitted: 'primary',
}

export const OnBoardStatuses = {
	PENDING: 'Pending',
	REJECTED: 'Rejected',
	SUBMITTED: 'Submitted',
	RE_SUBMITTED: 'Re-Submitted',
	APPROVED: 'Approved',
}

export default InvitedClientsContainer
