import DocumentPreview from 'components/Common/DocumentPreview'
import { Fragment, useMemo, useState } from 'react'
import { Col, Row } from 'reactstrap'
import FilePreviewCard from 'theme/ui/file-preview-card'

const ClientInfo = ({ client }) => {
	const clientProfile = useMemo(
		() => ({
			address1: client?.userProfile?.address_1,
			address2: client?.userProfile?.address_2,
			city: client?.userProfile?.city,
			unit: client?.userProfile?.unit,
			province: client?.userProfile?.province,
			zip_code: client?.userProfile?.zip_code,
			country: client?.userProfile?.country,
		}),
		[client],
	)

	const [isOpen, setIsOpen] = useState(false)
	const [url, setUrl] = useState(null)

	const toggle = async (url) => {
		setIsOpen(!isOpen)
		setUrl(url)
	}

	return (
		<Fragment>
			<DocumentPreview url={url} toggle={toggle} isOpen={isOpen} />
			<div>
				<div className='mb-4 rounded-lg bg-white'>
					<div className='bg-[#F2F5FF] p-4 py-3'>
						<h5 className='font-bold'>Personal Information</h5>
					</div>
					<Row className='m-0 border-b p-3'>
						<Col xs={6}>
							<span className='text-gray-500'>Full Name</span>
						</Col>
						<Col xs={6}>
							<strong>
								{client?.first_name} {client?.last_name}
							</strong>
						</Col>
					</Row>
					<Row className='m-0 border-b p-3'>
						<Col xs={6}>
							<span className='text-gray-500'>E-Mail</span>
						</Col>
						<Col xs={6}>
							<strong>{client?.email}</strong>
						</Col>
					</Row>
					<Row className='m-0 p-3'>
						<Col xs={6}>
							<span className='text-gray-500'>Contact Phone</span>
						</Col>
						<Col xs={6}>
							<strong>{client?.phone_number}</strong>
						</Col>
					</Row>
				</div>

				<div>
					<div className='mb-4 rounded-lg bg-white'>
						<div className='bg-[#F2F5FF] p-4 py-3'>
							<h5 className='font-bold'>Address Information</h5>
						</div>
						<Row className='m-0 border-b p-3'>
							<Col xs={6}>
								<span className='text-gray-500'>Address 1</span>
							</Col>
							<Col xs={6}>
								<strong>{clientProfile?.address1}</strong>
							</Col>
						</Row>
						<Row className='m-0 border-b p-3'>
							<Col xs={6}>
								<span className='text-gray-500'>Address 2</span>
							</Col>
							<Col xs={6}>
								<strong>{clientProfile?.address2}</strong>
							</Col>
						</Row>
						<Row className='m-0 border-b p-3'>
							<Col xs={6}>
								<span className='text-gray-500'>Unit</span>
							</Col>
							<Col xs={6}>
								<strong>{clientProfile?.unit || '-'}</strong>
							</Col>
						</Row>
						<Row className='m-0 border-b p-3'>
							<Col xs={6}>
								<span className='text-gray-500'>City</span>
							</Col>
							<Col xs={6}>
								<strong>{clientProfile?.city}</strong>
							</Col>
						</Row>
						<Row className='m-0 border-b p-3'>
							<Col xs={6}>
								<span className='text-gray-500'>Province</span>
							</Col>
							<Col xs={6}>
								<strong>{clientProfile?.province}</strong>
							</Col>
						</Row>
						<Row className='m-0 border-b p-3'>
							<Col xs={6}>
								<span className='text-gray-500'>Zip Code</span>
							</Col>
							<Col xs={6}>
								<strong>{clientProfile?.zip_code}</strong>
							</Col>
						</Row>
						<Row className='m-0 border-b p-3'>
							<Col xs={6}>
								<span className='text-gray-500'>Country</span>
							</Col>
							<Col xs={6}>
								<strong>{clientProfile?.country}</strong>
							</Col>
						</Row>
					</div>
				</div>

				<div>
					<div className='mb-4 rounded-lg bg-white'>
						<div className='bg-[#F2F5FF] p-4 py-3'>
							<h5 className='font-bold'>Documents</h5>
						</div>

						<Row className='m-0 border-b p-3'>
							<Col xs={6}>
								<span className='text-gray-500'>SIN</span>
							</Col>
							<Col xs={6}>
								<strong>{client?.sin_number}</strong>
							</Col>
						</Row>
						<Row className='m-0 items-center border-b p-3'>
							<Col xs={6}>
								<span className='text-gray-500'>Valid Document</span>
							</Col>
							<Col xs={6}>
								<FilePreviewCard
									path={client?.valid_document?.path}
									mime_type={client?.valid_document?.mime_type}
									name={client?.valid_document?.name}
									title='Valid ID'
								/>
							</Col>
						</Row>
						<Row className='m-0 items-center border-b p-3'>
							<Col xs={6}>
								<span className='text-gray-500'>Government Document</span>
							</Col>
							<Col xs={6}>
								<FilePreviewCard
									path={client?.government_document?.path}
									mime_type={client?.government_document?.mime_type}
									name={client?.government_document?.name}
									title='Government Document'
								/>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default ClientInfo
