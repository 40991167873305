/* eslint-disable react-hooks/exhaustive-deps */
import { createColumnHelper } from '@tanstack/react-table'
import base64 from 'base-64'
import { Fragment, useState } from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'

import { ButtonLink, DataTable } from 'components/Common'
import FilterForm from './FilterForm'
import useWorkflowViewQuery from './useWorkflowViewQuery'

const Workflows = ({ client, role }) => {
	const user = {
		id: client?.id,
		label: `${client?.first_name} ${client?.last_name} (${client?.email})`,
	}

	const columnHelper = createColumnHelper()

	const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
	const { data, meta, isLoading } = useWorkflowViewQuery(pagination, client, role)

	const columns = [
		columnHelper.accessor('id', {
			id: 'id',
			size: 40,
			header: () => <span>#</span>,
			cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		}),

		columnHelper.accessor((row) => row.name, {
			id: 'name',
			size: 200,
			header: () => <span>Name</span>,
			cell: (info) => <span>{info.getValue()}</span>,
		}),

		columnHelper.accessor((row) => row.status, {
			id: 'status',
			size: 200,
			header: () => <span>Status</span>,
			cell: (info) => <span>{info.getValue()}</span>,
		}),

		// columnHelper.accessor((row) => row, {
		// 	id: 'action',
		// 	size: 200,
		// 	header: () => <span>Action</span>,
		// 	cell: (info) => <Actions row={info.getValue()} />,
		// }),
	]

	return (
		<Fragment>
			<Card className='!rounded-lg shadow-none'>
				<CardHeader className='!border-none !bg-[#F2F5FF] p-4 py-3'>
					<h5 className='mb-0 font-bold'>Workflows</h5>
					<div>
						<ButtonLink
							to={`/workflows/assign?user=${base64.encode(JSON.stringify(user))}`}
							size='sm'
							color='primary'>
							<i className='uil uil-plus' /> Assign Workflow
						</ButtonLink>
					</div>
				</CardHeader>
				<CardBody>
					<FilterForm />
					<DataTable
						columns={columns}
						data={data}
						isLoading={isLoading}
						pagination={pagination}
						setPagination={setPagination}
						meta={meta}
					/>
				</CardBody>
			</Card>
		</Fragment>
	)
}

export default Workflows
