import { Fragment, useRef, useState } from 'react'

import { useUploadFileMutation } from 'modules/files/file-apis'
import FilePreviewCard from 'theme/ui/file-preview-card'
import { FileUploadField } from 'theme/ui/forms'
import notification from 'utilities/notification'

const FileUploadCard = ({ file, title, onSetFileId }) => {
	const [document, setDocument] = useState(file)
	const [isUploading, setIsUploading] = useState(false)
	const fileRef = useRef(null)
	const [uploadFile] = useUploadFileMutation()

	const onChange = async ($file) => {
		try {
			if ($file) {
				setIsUploading(true)
				onSetFileId(null)
				const formData = new FormData()
				formData.append('file', $file)
				const response = await uploadFile({ payload: formData })
				setIsUploading(false)
				if (response?.data?.status === 200) {
					notification(
						'success',
						'File uploaded successfully, please click on update button to save',
					)

					onSetFileId(response?.data?.data?.fileID)
				}
			}
		} catch (error) {
			setIsUploading(false)
		}
	}

	return (
		<Fragment>
			{document ? (
				<div>
					<FilePreviewCard
						path={document?.path}
						mime_type={document?.mime_type}
						name={document?.name}
						title={title}
					/>
					<span
						className='float-right mt-1 cursor-pointer text-sm text-main hover:underline'
						onClick={() => setDocument(null)}>
						Re-upload
					</span>
				</div>
			) : (
				<FileUploadField
					isUploading={isUploading}
					label={null}
					onUpload={(file) => onChange(file)}
					isMarginBottom={false}
					fileRef={fileRef}
					allowedExtentions='.jpg,.jpeg,.png,.pdf'
				/>
			)}
		</Fragment>
	)
}

export default FileUploadCard
