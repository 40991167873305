import { Dialog, Transition } from '@headlessui/react'
import { useOnboardClientsMutation } from 'modules/clients/invited-client-api'
import { ClientWorkflowAssignForm } from 'modules/workflows/workflow-template'
import { Fragment, useState } from 'react'
import { Button } from 'theme/ui/forms'
import notification from 'utilities/notification'

const OnboardPopup = ({ isOpen, onClose, selectedRows }) => {
	const [isChecked, setIsChecked] = useState(false)
	const [selectedIndex, setSelectedIndex] = useState(0)
	const [onboardClient] = useOnboardClientsMutation()
	const [isLoading, setIsLoading] = useState(false)

	const onOnboard = async () => {
		try {
			setIsLoading(true)
			const payload = {
				clients: selectedRows,
				isEmailSend: true,
			}
			const response = await onboardClient(payload)
			setIsLoading(false)
			if (response?.data?.status === 200) {
				onClose()
				notification('info', response?.data?.message)
			}
		} catch (error) {
			setIsLoading(false)
		}
	}

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-[1008]' onClose={() => {}}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/30' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'>
							<Dialog.Panel className='w-full max-w-3xl transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all'>
								<div className='top-o relative right-0'>
									<button
										type='button'
										className='absolute right-0 top-0'
										onClick={onClose}>
										<i className='fas fa-times text-lg' />
									</button>
								</div>
								<div className='flex flex-col items-center'>
									<Dialog.Title
										as='h3'
										className='mb-3 text-center text-lg font-medium leading-6 text-gray-900'>
										You have chosen to onboard {selectedRows?.length} clients
										into the system.
									</Dialog.Title>
									<div className='p-3'>
										<div className='mb-3 flex items-center'>
											<input
												checked={isChecked}
												type='checkbox'
												name='is_workflow_assign'
												id='is_workflow_assign'
												className='form-check-input m-0'
												onChange={(e) => setIsChecked(e.target.checked)}
											/>
											&nbsp;
											<label
												htmlFor='is_workflow_assign'
												className='m-0 text-sm font-bold text-[#495057]'>
												Would you like to assign a workflow to the selected
												clients?
											</label>
										</div>

										{isChecked ? (
											<ClientWorkflowAssignForm
												setSelectedIndex={setSelectedIndex}
												selectedIndex={selectedIndex}
												initialValues={{}}
												onClose={onClose}
												selectedClients={selectedRows}
											/>
										) : undefined}
									</div>

									{!isChecked ? (
										<div className='flex items-center justify-center space-x-3'>
											<Button
												type='button'
												size='sm'
												variant='white'
												onClick={onClose}>
												Cancel
											</Button>
											<Button
												type='button'
												size='sm'
												onClick={onOnboard}
												isLoading={isLoading}>
												Onboard Now
											</Button>
										</div>
									) : undefined}
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export default OnboardPopup
