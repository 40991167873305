/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'

import { ButtonLink, Loader } from 'components/Common'
import { ClientVerifyForm, preparePayload, useInitialValues } from 'modules/clients/client-verify'
import InvitedClientQuery from 'modules/clients/manual-verification/InvitedClientQuery'
import { store } from 'store/common/actions'
import AppLayout from 'theme/layouts/app-layout'
import notification from 'utilities/notification'
import hasPermission from 'utils/checkPermission'
import { showSuccessToast } from 'utils/commonFunctions'
import UnAuthorize from '../StaticPages/UnAuthorize'

const ClientVerify = (props) => {
	const {
		match: { params },
		location,
		history,
	} = props
	const { id } = params
	const isReadPermission = hasPermission('client:read')
	const { client, isLoading } = InvitedClientQuery(id)
	const initialValues = useInitialValues({ client })
	const dispatch = useDispatch()
	const [activeTab, setActiveTab] = useState(1)

	const onSubmit = (values) => {
		const payload = preparePayload(values)
		const URL = `users/${id}/user-onboard-detail`
		dispatch(store(URL, payload)).then((response) => {
			if (response?.status === 200) {
				showSuccessToast(response?.message)
				history.push('/clients')
			}
		})
	}

	useEffect(() => {
		if (!isLoading && client.length === 0) {
			notification(
				'warn',
				'The client has not yet completed filling out all the necessary information.',
			)
			history.goBack()
		}
	}, [isLoading, client])

	return (
		<AppLayout
			meta={{
				title: 'Verify client information',
			}}>
			{!isReadPermission ? (
				<UnAuthorize />
			) : (
				<div className='page-content'>
					{isLoading ? (
						<Loader />
					) : (
						<Container fluid>
							<div className='mb-3 flex items-center justify-between'>
								<h3 className='text-xl'>Mannual Verification</h3>
								<ButtonLink to='/clients' size='sm' color='primary'>
									Back
								</ButtonLink>
							</div>

							<Row>
								<Col lg='12'>
									<Card>
										<CardBody className='p-0'>
											<ClientVerifyForm
												location={location}
												history={history}
												initialValues={initialValues}
												onSubmit={onSubmit}
												client={client}
												tabState={[activeTab, setActiveTab]}
											/>
										</CardBody>
									</Card>
								</Col>
							</Row>
						</Container>
					)}
				</div>
			)}
		</AppLayout>
	)
}

export default ClientVerify
