import CryptoJS from 'crypto-js'
import moment from 'moment'
import store from '../store/index'

export const formatDate = (timestamp) => {
	if (!timestamp) {
		return ''
	}
	return moment(timestamp).format('MM/DD/yyyy hh:mm A')
}

export const formatPrice = (price, currency = 'USD') => {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: currency,
	})
	return formatter.format(price)
}

export const getStatusVariant = (status) => {
	switch (status?.toLowerCase()) {
		case 'completed':
			return 'success'
		case 'pending':
			return 'warning'
		case 'failed':
			return 'danger'
		default:
			return 'secondary'
	}
}
export const getSubStatusVariant = (status) => {
	switch (status?.toLowerCase()) {
		case 'active':
			return 'success'
		case 'inactive':
			return 'danger'
		default:
			return 'secondary'
	}
}

export const getParseQueryString = (url) => {
	const queryString = url.split('?')[1]

	if (!queryString) {
		return {}
	}

	const params = new URLSearchParams(queryString)
	const result = {}

	params.forEach((value, key) => {
		result[key] = value
	})

	return result
}

export const getPaginationParams = (url) => {
	const _parseQueryString = getParseQueryString(url)
	return {
		page: Number(_parseQueryString?.page) || 1,
		limit: Number(_parseQueryString?.limit) || 10,
	}
}

export const getTagsParams = (url) => {
	const _parseQueryString = getParseQueryString(url)
	return {
		tags: _parseQueryString || [],
	}
}

export const formatToTwoDigits = (number) => {
	let numString = number.toString()

	if (numString.length < 2) {
		numString = '0' + numString
	}

	return numString
}

export const findLargestInteger = (arr) => {
	if (!arr || arr.length === 0) {
		return 0
	}

	let largest = arr[0]

	for (let i = 1; i < arr.length; i++) {
		if (arr[i] > largest) {
			largest = arr[i]
		}
	}

	return largest
}

export function getUserTimezone() {
	return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const generateUniqueId = () => {
	const timestamp = Date.now().toString(36)
	const randomString = Math.random().toString(36).substr(2, 5)
	return timestamp + randomString
}

export const getTimer = (timeString) => {
	const [duration, periodUnit] = timeString.split(' ')
	let expiredTime = 0
	switch (periodUnit) {
		case 'sec':
			expiredTime = moment().add(duration, 'seconds')
			break
		case 'min':
			expiredTime = moment().add(duration, 'm')
			break
		case 'h':
			expiredTime = moment().add(duration, 'hours')
			break
		case 'd':
			expiredTime = moment().add(duration, 'days')
			break
		case 'w':
			expiredTime = moment().add(duration, 'weeks')
			break
	}

	return expiredTime
}

export const formatFileSize = (bytes) => {
	const sizes = ['Bytes', 'KB', 'MB', 'GB']
	if (bytes === 0) return '0 Byte'
	const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
	return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i]
}

export const encrypt = (data) => {
	const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY
	return CryptoJS.AES.encrypt(data, encryptionKey).toString()
}

export const decrypt = (data) => {
	const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY
	return CryptoJS.AES.decrypt(data, encryptionKey).toString(CryptoJS.enc.Utf8)
}

export const encryptPayload = (data) => {
	const isEncryptionEnabled = ['production', 'development'].includes(process.env.REACT_APP_ENV)

	if (isEncryptionEnabled) {
		return encrypt(JSON.stringify(data))
	}

	return data
}

export const decryptResponse = (response) => {
	const isEncryptionEnabled = ['production', 'development'].includes(process.env.REACT_APP_ENV)

	if (isEncryptionEnabled) {
		const descrypt = decrypt(response?.data)
		return JSON.parse(JSON.parse(descrypt))
	}

	return response?.data
}

export const isAllowTo = (permission) => {
	try {
		const defaultGroup = store?.getState()?.Permissions?.defaultGroup
		const permissions = defaultGroup?.group?.accesses || []
		const $permission = permissions.find((p) => p?.module === permission)
		return $permission ? true : false
	} catch (error) {
		return false
	}
}

export const createSelectOption = (value) => ({
	label: value,
	value: value,
})

export const WorkflowStatuses = {
	ASSIGNED: 'Assigned',
	IN_REVIEW: 'In Review',
	REJECTED: 'Rejected',
	DRAFT: 'Draft',
	FINAL_REVIEWER_REJECTED: 'Final Reviewer Rejected',
	APPROVED: 'Approved',
	FINAL_REVIEWER_APPROVED: 'Final Reviewer Approved',
	IN_FINAL_REVIEW: 'In Final Review',
	GATHERING_SIGNATURE: 'Gathering Signatures',
	COMPLETED: 'Completed',
	CANCELLED: 'Cancelled',
}
