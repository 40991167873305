import { Field } from 'formik'
import { useState } from 'react'

import { CreatableSelectField } from 'theme/ui/forms'
import ReceiverQuery from '.'

const ReceiverEmailDropdown = ({ formValues }) => {
	const [search, setSearch] = useState('')
	const { receiver, isLoading } = ReceiverQuery(search)
	const handleSearch = (search) => {
		setSearch(search)
	}
	const onCreateOption = (value) => {
		return value
	}
	return (
		<>
			<Field
				label='Select Receiver'
				type='select'
				name='receiver_emails'
				options={receiver}
				isMulti
				isLoading={isLoading}
				onInputChange={(value) => handleSearch(value)}
				component={CreatableSelectField}
				formValues={formValues}
				onCreateOption={onCreateOption}
				helperText={`Kindly provide the email addresses of anyone you wish to receive the documents
				related to this workflow once it&apos;s completed`}
			/>
		</>
	)
}

export default ReceiverEmailDropdown
