import classNames from 'classnames'
import { useMemo, useState } from 'react'
import Spinner from 'theme/ui/spinner'
import { validateFileSize, validateFileType } from '.'
import { sanitizeImage } from './image-validation'
import { sanitizePdf } from './pdf-validation'

const FileUploadField = ({
	id,
	label = 'Upload file',
	isDisabled,
	isRequiredField,
	labelHelperText,
	allowedExtentions,
	maxSize = 10000000,
	minSize = 100,
	onUpload,
	isUploading,
	fileRef,
	isMarginBottom = true,
}) => {
	const [error, setError] = useState(null)

	const $allowedExtentions = useMemo(
		() => (allowedExtentions ? allowedExtentions.split(',') : []),
		[allowedExtentions],
	)

	const onChange = async (e) => {
		try {
			setError(null)
			const file = e.target.files[0]
			const [type, subtype] = file.type.split('/')

			await validateFileType(file, $allowedExtentions)
			await validateFileSize(file, maxSize, minSize)

			if (type === 'image') {
				await sanitizeImage(file)
			}

			if (type === 'application' && subtype === 'pdf') {
				await sanitizePdf(file)
			}

			if (onUpload) {
				onUpload(file)
			}
		} catch (error) {
			setError(error?.message)
		}
	}

	return (
		<div
			className={classNames('form-group', {
				'mb-3': isMarginBottom,
			})}>
			{label ? (
				<label
					htmlFor={id}
					className='flex items-baseline justify-between text-[14px] font-bold text-[#495057]'>
					<span>
						{label}{' '}
						{isRequiredField ? <span className='text-red-500'>*</span> : undefined}
					</span>
					{labelHelperText ? (
						<span className='text-[11px] font-normal'>
							<span className='text-red-500'>*</span> This asterisk indicates not
							optional
						</span>
					) : undefined}
				</label>
			) : undefined}
			<div className='flex flex-col'>
				<div className='relative'>
					<input
						id={id}
						ref={fileRef}
						onChange={onChange}
						type='file'
						disabled={isDisabled}
						className={classNames('form-control placeholder:!text-[#B5B5C3]', {
							'is-invalid': error !== null,
						})}
						accept={allowedExtentions}
					/>

					{isUploading ? (
						<div className='absolute inset-0 z-20 flex cursor-not-allowed select-none items-center justify-center space-x-2 rounded !border border-[#ced4da] bg-[#f5f6f8]'>
							<Spinner />
							<span className='text-sm text-black'>Uploading...</span>
						</div>
					) : undefined}
				</div>

				{allowedExtentions ? (
					<span className='flex flex-wrap space-x-1 text-[13px]'>
						<span className='text-gray-400'>Allowed file types:</span>{' '}
						<span>{allowedExtentions.split(',').join(', ')}</span>
					</span>
				) : undefined}

				{error ? <div className='text-[13px] text-[#f46a6a]'>{error}</div> : undefined}
			</div>
		</div>
	)
}

export default FileUploadField
